export function addressToString(address: starboard.tables["address"]) {
  const { id: _a, created_at: _b, ...addressValues } = address;
  return Object.values(addressValues).join(", ");
}

interface AddressProps {
  value: starboard.tables["address"];
}

export const Address = (props: AddressProps) => {
  return <span>{addressToString(props.value)}</span>;
};
