import { Outlet } from "react-router";
import { PageContent } from "../shared/components/Page";

export function CrewSearchContainer() {
  return (
    <PageContent style={{ height: "100%" }}>
      <Outlet />
    </PageContent>
  );
}
