import { fallbackOnNullQueryArg, useSubQuery } from "../helpers/reactHelpers";
import { useUserOwnerId } from "./ownersClient";
import { supabase } from "./supabaseClient";
import { useQuery } from "../helpers/reactHelpers";
import { PostgrestFilterBuilder } from "@supabase/postgrest-js";
import { executeCommand } from "./supabaseHelpers";
import { useAcceptedOffersForUserCrew } from "./offersClient";

export type VoyageStatus =
  | "in_progress"
  | "cancelled"
  | "not_started"
  | "completed";

// A helper to derive status from a Voyage.
export const getStatus = (voyage: starboard.Voyage): VoyageStatus => {
  if (voyage.cancellations) return "cancelled";
  if (voyage.completions) return "completed";
  if (voyage.starts) return "in_progress";
  return "not_started";
};

// A helper to derive if a Voyage has an unfinished status.
export const isUnfinished = (voyage: starboard.Voyage): boolean => {
  switch (getStatus(voyage)) {
    case "cancelled":
    case "completed":
      return false;
    default:
      return true;
  }
};

/**
 * Creates a filter builder for voyages. This should be used any
 * time voyages are being selected.
 *
 * @returns filter builder for voyages - await it to get them.
 */
const selectVoyages = () => {
  return supabase.from("voyage").select(`
      id,
      created_at,
      to_address:to_address_id!inner(*),
      from_address:from_address_id!inner(*),
      vessel!inner(*),
      positions:voyage_crew_position (*, offers:offer (*)),
      starts:voyage_start(*),
      cancellations:voyage_cancellation(*),
      completions:voyage_completion(*)
     `) as PostgrestFilterBuilder<any, any, starboard.Voyage>;
};

/**
 * Creates a query for the voyage with the matching id.
 *
 * @param voyageId
 * @returns
 */
const selectVoyage = fallbackOnNullQueryArg((voyageId: string) =>
  selectVoyages().eq("id", parseInt(voyageId)).single()
);

/**
 * Creates a query for all voyages involving a vessel owned by
 * the provided owner.
 *
 * @param ownerId
 * @returns
 */
const selectVoyagesForOwner = fallbackOnNullQueryArg((ownerId: number) =>
  selectVoyages().eq("vessel.owner_id", ownerId)
);

export const startVoyage = (voyageId: number, crewId: number) =>
  executeCommand("voyages/depart", { voyageId, crewId });

export const cancelVoyage = async (voyageId: number, crewId: number) => {
  try {
    return supabase.from("voyage_cancellation").insert({
      voyage_id: voyageId,
      crew_id: crewId,
    });
  } catch (e) {
    console.error(e);
  }
};

export const completeVoyage = async (voyageId: number, crewId: number) => {
  try {
    const result = await supabase.from("voyage_completion").insert({
      voyage_id: voyageId,
      crew_id: crewId,
    });

    return result;
  } catch (e) {
    console.error(e);
  }
};

export const useVoyage = (voyageId: string | undefined) => {
  const result = useQuery(selectVoyage, [voyageId]);

  return result;
};

export const useOwnerVoyages = () => {
  const userOwnerIdQuery = useUserOwnerId();

  return useQuery(selectVoyagesForOwner, [userOwnerIdQuery]);
};

export const useOwnerVoyageInProgress = () => {
  const acceptedOffers = useAcceptedOffersForUserCrew();
  return useSubQuery(
    acceptedOffers,
    (offers) =>
      (offers.data?.filter(
        (offer) =>
          getStatus(offer.offer.voyage_crew_position.voyage) === "in_progress"
      ) ?? [])[0]
  );
};

export const useOwnerVoyagesNotStarted = () => {
  const acceptedOffers = useAcceptedOffersForUserCrew();
  return useSubQuery(
    acceptedOffers,
    (offers) =>
      offers.data?.filter(
        (offer) =>
          getStatus(offer.offer.voyage_crew_position.voyage) === "not_started"
      ) ?? []
  );
};
