import { useQuery } from "../helpers/reactHelpers";
import { fallbackOnNullQueryArg } from "../helpers/reactHelpers";
import { useUserOwner } from "./ownersClient";
import { supabase } from "./supabaseClient";

export type Vessel = starboard.tables["vessel"];

const selectVesselsForOwner = fallbackOnNullQueryArg((ownerId: number) =>
  supabase.from("vessel").select().eq("owner_id", ownerId)
);

export const useOwnerVessels = () => {
  const userOwnerQuery = useUserOwner();
  return useQuery(selectVesselsForOwner, [userOwnerQuery.data?.id]);
};
