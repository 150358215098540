import React from "react";
import { useNavigate } from "react-router";
import {
  fetchMapDetails,
  useSearchFormState,
} from "../shared/clients/searchForm";
import { Elements } from "@stripe/react-stripe-js";
import { stripeClient } from "../shared/clients/stripeClient";
import { useRef, useState } from "react";
import SetupForm from "./SetupForm";
import {
  makeOffer,
  useSetupIntentForUser,
} from "../shared/clients/offersClient";
import { useUserOwner } from "../shared/clients/ownersClient";
import { Card } from "../shared/components/Card";
import { UserName } from "../shared/components/UserName";

const useCrewSearchMapDetails = () => {
  const { crewSearchParams } = useSearchFormState();
  const [details, setDetails] =
    useState<Awaited<ReturnType<typeof fetchMapDetails>>>();
  const prevFrom = useRef<string>();
  const prevTo = useRef<string>();
  if (
    crewSearchParams?.from !== prevFrom.current ||
    prevTo.current !== crewSearchParams?.to
  ) {
    fetchMapDetails(crewSearchParams?.from, crewSearchParams?.to).then(
      setDetails
    );
    prevFrom.current = crewSearchParams?.from;
    prevTo.current = crewSearchParams?.to;
  }
  return details;
};

export const CrewSearchReviewStep: React.FC<{}> = React.memo(
  function CrewSearchReviewStepInner(props) {
    const stripeForm = useRef<HTMLFormElement>(null);
    const setupIntentQuery = useSetupIntentForUser();
    const ownerQuery = useUserOwner();
    const { selectedCrew, selectedVessel, crewSearchParams } =
      useSearchFormState();

    const crewSearchMapDetails = useCrewSearchMapDetails();
    const isInvalidForm =
      !selectedCrew ||
      !selectedVessel ||
      !ownerQuery.data ||
      !crewSearchParams ||
      !crewSearchMapDetails?.toPlace ||
      !crewSearchMapDetails?.fromPlace;

    const navigate = useNavigate();
    const setupIntent = setupIntentQuery.data?.setupIntent;

    const onSuccessfulCheckout = async () => {
      if (
        isInvalidForm ||
        !setupIntent?.client_secret ||
        !ownerQuery.data ||
        !crewSearchMapDetails?.toPlace ||
        !crewSearchMapDetails?.fromPlace ||
        !crewSearchMapDetails?.toCoordinate ||
        !crewSearchMapDetails?.fromCoordinate
      ) {
        console.error("[payments] Cannot handle checkout, invalid data.");
        return;
      }

      await makeOffer({
        selectedCrewId: selectedCrew.id,
        from: {
          search: crewSearchParams.from,
          coordinate: crewSearchMapDetails.fromCoordinate,
          address: {
            address_lines: crewSearchMapDetails.fromPlace.fullThoroughfare,
            region: crewSearchMapDetails.fromPlace.administrativeArea,
            locality: crewSearchMapDetails.fromPlace.locality,
            postcode: crewSearchMapDetails.fromPlace.postCode,
            country: crewSearchMapDetails.fromPlace.country,
          },
        },
        to: {
          search: crewSearchParams.to,
          coordinate: crewSearchMapDetails.toCoordinate,
          address: {
            address_lines: crewSearchMapDetails.toPlace.fullThoroughfare,
            region: crewSearchMapDetails.toPlace.administrativeArea,
            locality: crewSearchMapDetails.toPlace.locality,
            postcode: crewSearchMapDetails.toPlace.postCode,
            country: crewSearchMapDetails.toPlace.country,
          },
        },
        selectedVessel,
        setupIntentId: setupIntent.id,
        ownerId: ownerQuery.data.id,
      });

      // Resolve back to offers page where the new offer should
      // now appear.
      navigate("/owner/deliveries/");
    };

    if (isInvalidForm) return null;
    if (!setupIntent)
      return <span style={{ margin: "0.5rem" }}>Loading...</span>;

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Card>
            <h3>Captain</h3>
            <UserName userId={selectedCrew.user_id} />
            {/* TODO: Add edit */}
          </Card>
          <Card>
            <h3>Vessel</h3>
            <span>{selectedVessel.name}</span>
            {/* TODO: Add edit */}
          </Card>
          <Card>
            <h3>Payment</h3>
            {/* TODO: Add select existing payment method. */}
            {setupIntent.client_secret && (
              <Elements
                stripe={stripeClient}
                options={{
                  clientSecret: setupIntent.client_secret,
                }}
              >
                <SetupForm
                  ref={stripeForm}
                  onSuccessfulCheckout={onSuccessfulCheckout}
                />
              </Elements>
            )}
          </Card>
        </div>
        {/* controls */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 0,
          }}
        >
          <button
            style={{
              backgroundColor: "var(--sb-dark-gray)",
              fontWeight: "bold",
              color: "var(--sb-lightest-gray)",
              padding: "1rem",
              width: "45%",
            }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "var(--sb-yellow)",
              fontWeight: "bold",
              color: "var(--sb-dark-gray)",
              padding: "1rem",
              width: "45%",
            }}
            onClick={() => {
              stripeForm.current?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              );
            }}
          >
            Request Trip
          </button>
        </Card>
      </div>
    );
  }
);
