import React from "react";
import { useNavigate } from "react-router-dom";
import { ProfileAvatar } from "../shared/components/Avatar";
import { Card } from "../shared/components/Card";
import { UserName } from "../shared/components/UserName";
import { useSearchFormState } from "../shared/clients/searchForm";

export const CrewSearchBrowseStep: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { crewSearchResults, crewSearchParams, selectCrew } =
    useSearchFormState();

  return (
    <div>
      <Card
        style={{
          background: "var(--sb-lightest-gray)",
          borderRadius: "2.5rem",
          padding: ".75rem",
          textAlign: "center",
        }}
      >
        <span>{crewSearchParams?.from}</span>
        <span
          style={{
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
            fontWeight: "bold",
            color: "var(--sb-medium-dark-gray)",
          }}
        >
          {"->"}
        </span>
        <span>{crewSearchParams?.to}</span>
      </Card>
      {crewSearchResults?.map((crew) => (
        <Card
          key={crew.id}
          style={{ cursor: "pointer" }}
          onClick={() => {
            selectCrew(crew);
            navigate("/owner/search/vessel");
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateRows: "1fr",
              gridTemplateColumns: "4rem 1fr 6rem",
              fontWeight: "bold",
              fontSize: "1.25rem",
            }}
          >
            <ProfileAvatar profileId={crew.user_id} size={64} />
            <div
              style={{
                marginLeft: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <UserName userId={crew.user_id} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  verticalAlign: "center",
                  background: "var(--sb-lightest-gray)",
                  borderRadius: "2.5rem",
                  padding: ".75rem",
                }}
              >
                ${crew.daily_rate_dollars}/day
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
