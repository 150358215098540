import { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";

export function BottomTab(props: PropsWithChildren<{ to: string }>) {
  const location = useLocation();
  const isCurrentTab = location.pathname.includes(props.to);
  return (
    <Link
      to={props.to}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: isCurrentTab ? "bold" : "normal",
        fontSize: "0.75rem",
        borderBottom: isCurrentTab ? "solid 0.25rem var(--sb-yellow)" : "none",
        padding: "0.5rem",
      }}
    >
      {props.children}
    </Link>
  );
}
