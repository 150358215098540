// Properties whose values are not functions.
// Useful helper in stores to isolate the data from the data setters.
export type Functionless<Target> = {
  [K in keyof Target as Target[K] extends Function ? never : K]: Target[K];
};

type Nullable<T> = T | undefined | null;
type NullableItems<A extends any[]> = A extends [infer B]
  ? [Nullable<B>]
  : A extends [infer B, infer C]
  ? [Nullable<B>, Nullable<C>]
  : A extends [infer B, infer C, infer D]
  ? [Nullable<B>, Nullable<C>, Nullable<D>]
  : A extends [infer B, infer C, infer D, infer E]
  ? [Nullable<B>, Nullable<C>, Nullable<D>, Nullable<E>]
  : A extends [infer B, infer C, infer D, infer E, infer F]
  ? [Nullable<B>, Nullable<C>, Nullable<D>, Nullable<E>, Nullable<F>]
  : never;

export function fallbackOnNullArg<Args extends any[], Result, Fallback = void>(
  strictFunc: (...args: Args) => Result,
  resultIfNullArg: Fallback
): (...args: NullableItems<Args>) => Result | Fallback {
  return (...args: NullableItems<Args>) => {
    if (args.some((arg) => arg === null || arg === undefined))
      return resultIfNullArg;

    return strictFunc(...(args as unknown as Args));
  };
}
/*
// Takes a function type and returns it but with args that can be null
// or undefined.
type NullableArgs<F extends (...args: any[]) => unknown> = (
  ...args: NullableItems<Parameters<F>>
) => ReturnType<F>;
*/
