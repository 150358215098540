import "../index.css";
import { Card } from "../shared/components/Card";
import {
  VoyageStatus,
  getStatus,
  useOwnerVoyages,
} from "../shared/clients/voyagesClient";
import { Address } from "../shared/components/AddressDisplay";
import { VoyageMap } from "../shared/components/VoyageMap";
import { CrewName } from "../shared/components/UserName";
import { useOffersForUserOwner } from "../shared/clients/offersClient";
import React from "react";
import { PageContent } from "../shared/components/Page";

/**
 * @todo factor out to be shared.
 */
export const VoyageStatusLabel: React.FC<{
  status: VoyageStatus;
}> = (props) => {
  switch (props.status) {
    case "cancelled":
      return <span>Cancelled</span>;

    case "in_progress":
      return <span>Delivery in progress</span>;

    case "completed":
      return <span>Delivered</span>;

    case "not_started":
      return <span>Waiting for pick up</span>;

    default:
      return <span>Unknown Status</span>;
  }
};

export const VoyagesList = React.memo(() => {
  const voyages = useOwnerVoyages();
  const userOwnerTrips = [] as ReturnType<typeof useOffersForUserOwner>; //useOffersForUserOwner();

  if (voyages.status === "loading") {
    return null;
  }

  if (voyages.error) {
    return <span>Could not load voyages.</span>;
  }

  return (
    <PageContent>
      {voyages.data.length === 0 && userOwnerTrips.length === 0 && (
        <Card>No Delivery History</Card>
      )}
      {userOwnerTrips.map((request) => (
        <Card key={request.id} dataTestId={`offer-card-${request.id}`}>
          <VoyageMap voyage={request.voyage_crew_position.voyage} />
          <div style={{ margin: "0.5rem" }}>
            <label>Status</label>
            <h4>Pending Acceptance</h4>
            <label>Route</label>
            <div>
              <Address
                value={request.voyage_crew_position.voyage.from_address}
              />
              <span
                style={{
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  fontWeight: "bold",
                  color: "var(--sb-medium-dark-gray)",
                }}
              >
                {"->"}
              </span>
              <Address value={request.voyage_crew_position.voyage.to_address} />
            </div>
          </div>
          <div style={{ margin: "0.5rem" }}>
            <label>Offered At</label>
            <div>
              {request.created_at &&
                new Date(request.created_at).toLocaleString()}
            </div>
          </div>
          <div style={{ margin: "0.5rem" }}>
            <label>Captain</label>
            <div>
              <CrewName crewId={request.crew_id} />
            </div>
          </div>
        </Card>
      ))}
      {voyages.data.map((voyage) => (
        <Card key={voyage.id}>
          <VoyageMap voyage={voyage} />
          <div style={{ margin: "0.5rem" }}>
            <label>Vessel</label>
            <div>{voyage.vessel.name}</div>
          </div>
          <div style={{ margin: "0.5rem" }}>
            <label>Status</label>
            <h4>
              <VoyageStatusLabel status={getStatus(voyage)} />
            </h4>
            <label>Route</label>
            <div>
              <Address value={voyage.from_address} />
              <span
                style={{
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  fontWeight: "bold",
                  color: "var(--sb-medium-dark-gray)",
                }}
              >
                {"->"}
              </span>
              <Address value={voyage.to_address} />
            </div>
          </div>
        </Card>
      ))}
    </PageContent>
  );
});
