import React from "react";

type CardProps = React.PropsWithChildren<{
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => unknown;
  dataTestId?: string;
}>;

export function Card(props: CardProps) {
  return (
    <div
      onClick={props.onClick}
      style={{
        padding: "0.5rem",
        backgroundColor: "var(--sb-white)",
        borderRadius: "0.25rem",
        marginBottom: "0.5rem",
        ...props.style,
      }}
      data-testid={props.dataTestId}
    >
      {props.children}
    </div>
  );
}
