import create from "zustand";
import { persist } from "zustand/middleware";

const localStorageKey = `starboard-local-cache/enlist-form${
  process.env.NODE_ENV || "dev"
}`;

interface EnlistFormState {
  crewForm:
    | Partial<starboard.inserts["crew"] & { homeAddressDisplay: string }>
    | undefined;
  setCrewForm: (
    crewForm: Partial<
      starboard.inserts["crew"] & { homeAddressDisplay: string }
    >
  ) => void;
}

export const useEnlistFormState = create(
  persist<EnlistFormState>(
    (set, get) => ({
      crewForm: undefined,
      setCrewForm: (
        crewForm: Partial<
          starboard.inserts["crew"] & { homeAddressDisplay: string }
        >
      ) =>
        set({
          crewForm: { ...get().crewForm, ...crewForm },
        }),
    }),
    {
      name: localStorageKey,
    }
  )
);
