import { useQuery, useSubQuery } from "../helpers/reactHelpers";
import { fallbackOnNullQueryArg } from "../helpers/reactHelpers";
import { supabase } from "./supabaseClient";
import { useUserIdQuery } from "./userClient";

export const selectCustomerForUser = (userId: string) => {
  return supabase.from("customer").select().eq("user_id", userId).single();
};

const selectOwnerForUser = fallbackOnNullQueryArg((userId: string) => {
  console.log("[client/owners] Fetching owner for user id:", userId);
  return supabase.from("owner").select().eq("user_id", userId).single();
});

const selectOwnerIdFromUser = (q: Awaited<ReturnType<typeof useUserOwner>>) =>
  q.data?.id;

export const useUserOwner = () => {
  const userIdQuery = useUserIdQuery();
  return useQuery(selectOwnerForUser, [userIdQuery]);
};

export const useUserOwnerId = () => {
  const userOwner = useUserOwner();

  // Note: Splitting selectOwnerIdFromUser out as a function is intentional, this way
  // its reference does not change on render when it is passed into useSubQuery.
  return useSubQuery(userOwner, selectOwnerIdFromUser);
};
