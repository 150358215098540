import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey)
  throw new Error("Invalid supabase environment variables!");

export enum TableName {
  voyage = "voyage",
  voyageCrewPosition = "voyage_crew_position",
}

export const supabase = createClient<starboard.db>(
  supabaseUrl,
  supabaseAnonKey
);
