/**
 * Small wrapper around apple's mapkitjs library to make it easily
 * accessible around the app.
 *
 * @see documentation https://developer.apple.com/documentation/mapkitjs/
 *
 * @note This is added to index.html to provide global `mapkit` object:
 * <script src="https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js"></script>
 */

let appGeocoder: mapkit.Geocoder = new mapkit.Geocoder();

/**
 * Initializes mapkit client.
 * @param mapkit (must declare the type this way because the @types package for mapkit declares a namespace).
 * @returns initialized mapkit.
 */
export function createMapkit(mapkit: typeof window.mapkit) {
  console.log(`[mapkit] Importing and initializing...`);

  /**
   * This token must be signed using the private key downloaded from
   * apple developer tools.
   */
  const jwt = process.env.REACT_APP_MAPKIT_API_KEY;
  if (jwt === undefined) throw new Error("Please set REACT_APP_MAPKIT_API_KEY");

  mapkit.init({
    authorizationCallback: (done: (jwt: string) => unknown) => done(jwt),
  });

  mapkit.addEventListener("error", function (event: any) {
    console.error(`[mapkit] Error: ${JSON.stringify(event)}`);
  });

  return mapkit;
}

const starboardMapkit = createMapkit(window.mapkit);
export default starboardMapkit;

// Api call to get coordinate from address.
export async function lookup(
  address: string
): Promise<mapkit.Coordinate | undefined> {
  if (!appGeocoder) appGeocoder = new mapkit.Geocoder();

  return new Promise((resolve, reject) => {
    appGeocoder.lookup(
      address,
      (error, data) => {
        if (error) reject(error);
        if (data) resolve(data.results[0]?.coordinate);
      },
      { limitToCountries: "US" }
    );
  });
}

// Utils
export function getLatitudeDelta(...coordinates: mapkit.Coordinate[]) {
  return (
    Math.max(...coordinates.map((coordinate) => coordinate.latitude)) -
    Math.min(...coordinates.map((coordinate) => coordinate.latitude))
  );
}

export function getLongitudeDelta(...coordinates: mapkit.Coordinate[]) {
  return (
    Math.max(...coordinates.map((coordinate) => coordinate.longitude)) -
    Math.min(...coordinates.map((coordinate) => coordinate.longitude))
  );
}

export function getCenter(...coordinates: mapkit.Coordinate[]) {
  return new mapkit.Coordinate(
    Math.min(...coordinates.map((coordinate) => coordinate.latitude)) +
      getLatitudeDelta(...coordinates) / 2,
    Math.min(...coordinates.map((coordinate) => coordinate.longitude)) +
      getLongitudeDelta(...coordinates) / 2
  );
}

export function getSpan(...coordinates: mapkit.Coordinate[]) {
  return new mapkit.CoordinateSpan(
    getLatitudeDelta(...coordinates),
    getLongitudeDelta(...coordinates)
  );
}

export function getRegion(...coordinates: mapkit.Coordinate[]) {
  return new mapkit.CoordinateRegion(
    getCenter(...coordinates),
    getSpan(...coordinates)
  );
}

export function padRegion(region: mapkit.CoordinateRegion) {
  region.span.latitudeDelta =
    region.span.latitudeDelta + region.span.latitudeDelta * 1;
  region.span.longitudeDelta =
    region.span.longitudeDelta + region.span.longitudeDelta * 0.25;

  return region;
}

/**
 * Many many many services for marine routing,
 * nothing obvious and free yet.
 *
 * @see noaa
 * @see garmin https://activecaptain.garmin.com/en-US/pois/73206
 */
export function getRoute() {
  throw new Error("Not Implemented.");
}
// Looks up a Place(s) which match a coordinate.
export function reverseLookup(
  coordinate: mapkit.Coordinate
): Promise<mapkit.Place> {
  if (!appGeocoder) appGeocoder = new mapkit.Geocoder();

  return new Promise((resolve, reject) =>
    appGeocoder.reverseLookup(coordinate, (err, data) => {
      if (err) reject(err);
      if (data?.results?.[0]) resolve(data?.results?.[0]);
      reject("No data or error.");
    })
  );
}
