import React from "react";
import { useMemo } from "react";
import { addRouteAnnotation, useMap, useVoyageCoordinates } from "./useMap";

/**
 * Many many many services for marine routing,
 * nothing obvious and free yet.
 *
 * @see noaa
 * @see garmin https://activecaptain.garmin.com/en-US/pois/73206
 */

interface VoyageMapProps {
  voyage: starboard.Voyage;
  style?: React.CSSProperties;
}

// TODO: https://linear.app/starboardtracker/issue/STA-16/maps-not-loading-on-crew-home-page
// mapkit is throwing an error that parent must be defined.
export const VoyageMap = React.memo(
  (props: VoyageMapProps) => {
    // Using memoization to keep this data from changing when state
    // inside useMap or other hooks change is important.
    // If this changes due to a state change in this component then
    // the map will lose its parent.
    const parentElementId = React.useMemo(
      () =>
        `voyage-map/${props.voyage.id}/seed-${Math.floor(
          Math.random() * 1000
        )}`,
      [props.voyage.id]
    );

    const [fromCoord, toCoord] = useVoyageCoordinates(props.voyage);
    const map = useMap(parentElementId);
    useMemo(() => {
      if (!map) {
        console.debug("No map, not annotating route.");
        return;
      }

      addRouteAnnotation(map, fromCoord, toCoord);
    }, [map, fromCoord, toCoord]);

    return (
      <div
        id={parentElementId}
        style={{
          height: "25vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "uppercase",
          backgroundColor: "#CCC",
          ...props.style,
        }}
      ></div>
    );
  },
  (prev, next) => prev.voyage.id === next.voyage.id
);
