import Auth from "../auth/Auth";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppNavButton } from "./AppNavButton";
import { UserLink } from "./UserLink";
import { ReactElement } from "react";
import { RouteName } from "../../routes";
import { useUserSessionData } from "../clients/userClient";
import { Page, PageHeader } from "./Page";

/**
 * @todo push a 'from' route state in all Links and look it up
 * here to determine where to back out to.
 */
export function BackButton() {
  const navigate = useNavigate();

  return (
    <AppNavButton
      style={{ padding: 0, alignSelf: "end" }}
      onClick={() => navigate(-1)}
    >
      {"< Back"}
    </AppNavButton>
  );
}

const StarboardBadgeContainer: React.FC<React.PropsWithChildren<{}>> = (
  props
) => {
  return (
    <div
      style={{
        flex: 1,
        height: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
};

const StarboardBadge = () => {
  return (
    <img
      style={{ height: "100%", filter: "grayscale(100%)" }}
      src="/icon.png"
      alt="starboard"
    ></img>
  );
};

const StarboardBadgeLabel: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <div
      style={{
        height: "2rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <StarboardBadge />
      <h3 style={{ margin: 0, marginLeft: "0.5rem" }}>{props.children}</h3>
    </div>
  );
};

export const AppPageHeaderWithBackButton = () => {
  return (
    <PageHeader>
      <BackButton />
      <Link to="/">
        <StarboardBadgeContainer>
          <StarboardBadge />
        </StarboardBadgeContainer>
      </Link>
      <UserLink style={{ alignSelf: "center", justifySelf: "end" }} />
    </PageHeader>
  );
};

export const CrewAppPageHeader = () => {
  return (
    <PageHeader>
      <StarboardBadgeLabel>
        Starboard <span style={{ color: "var(--sb-yellow)" }}>Crew</span>
      </StarboardBadgeLabel>
      <div></div>
      <UserLink style={{ alignSelf: "center", justifySelf: "end" }} />
    </PageHeader>
  );
};

export const CrewAppPageHeaderWithBack = () => {
  return (
    <PageHeader>
      <BackButton />
      <Link to="/crew">
        <StarboardBadgeContainer>
          <StarboardBadge />
        </StarboardBadgeContainer>
      </Link>
      <UserLink style={{ alignSelf: "center", justifySelf: "end" }} />
    </PageHeader>
  );
};

export const AppPageHeader = () => {
  return (
    <PageHeader>
      <StarboardBadgeLabel>Starboard</StarboardBadgeLabel>
      <div></div>
      <UserLink style={{ alignSelf: "center", justifySelf: "end" }} />
    </PageHeader>
  );
};

export const AppPageHeaderWithBack = () => {
  return (
    <PageHeader>
      <BackButton />
      <Link to="/">
        <StarboardBadgeContainer>
          <StarboardBadge />
        </StarboardBadgeContainer>
      </Link>
      <UserLink style={{ alignSelf: "center", justifySelf: "end" }} />
    </PageHeader>
  );
};

export default function App(props: { header: ReactElement }) {
  const sessionQuery = useUserSessionData();

  if (!sessionQuery?.user?.id) {
    return <Auth />;
  }

  return (
    <Page>
      {props.header}
      <Outlet />
    </Page>
  );
}
