import { useEffect, useMemo, useState } from "react";
import { supabase } from "./supabaseClient";
import {
  QueryResult,
  ServerQueryResult,
  fallbackOnNullQueryArg,
  loadingResult,
  useQuery,
  useSubQuery,
} from "../helpers/reactHelpers";
import { AuthError, Session } from "@supabase/supabase-js";

export const selectProfileForUser = fallbackOnNullQueryArg((userId: string) =>
  supabase.from("profile").select().eq("id", userId).single()
);

export const useUserSessionQuery = () => {
  const [session, setSession] =
    useState<
      QueryResult<
        | { data: { session: Session }; error: null }
        | { data: null; error: AuthError }
      >
    >(loadingResult);

  useEffect(() => {
    async function refreshSession() {
      const result = await supabase.auth.getSession();

      if (!result.data.session && !result.error) {
        console.log("No session found");
        setSession({
          data: null,
          error: new AuthError("No session."),
        });
        return;
      }

      if (result.error) {
        console.error("Error fetching session:", result.error);
        setSession({
          data: null,
          error: result.error,
        });
        return;
      }

      if (result.data.session) {
        setSession({
          data: result.data,
          error: null,
        });
      }
    }

    supabase.auth.onAuthStateChange(() => refreshSession());
    refreshSession();
  }, []);

  return session;
};

export const useUserIdQuery = () => {
  const userSessionQuery = useUserSessionQuery();
  return useMemo(
    (): QueryResult<ServerQueryResult<string | undefined>> =>
      ({
        ...userSessionQuery,
        data:
          userSessionQuery.data === null
            ? null
            : userSessionQuery.data?.session?.user?.id,
      } as QueryResult<ServerQueryResult<string | undefined>>),
    [userSessionQuery]
  );
};

const selectSession = (q: Awaited<ReturnType<typeof useUserSessionQuery>>) =>
  q.data?.session;

const useUserSession = () => {
  return useSubQuery(useUserSessionQuery(), selectSession);
};

export const useUserSessionData = () => {
  return useUserSession().data;
};

export const useUserProfile = () => {
  const userSession = useUserSession();
  return useQuery(selectProfileForUser, [userSession?.data?.user?.id]);
};

// TODO - make atomic through a stored procedure, or
// a server side call and database transaction w/ a lib like postgres-js.
export const signUp = async (user: {
  email: string;
  password: string;
  name: string;
}) => {
  try {
    // Auth sign up must take place first because profile has a foreign
    // key to the user it creates.
    const { error: userError, data: userRes } = await supabase.auth.signUp({
      email: user.email,
      password: user.password,
    });
    if (userError) throw userError;

    const uuid = userRes.user?.id;
    if (!uuid) throw new Error("No uuid");

    // Now create the profile with custom info - we do not control the
    // auth.user table.
    const [first, ...rest] = user.name.split(" ");
    const last = rest.join(" ");
    const { data: profileRes, error } = await supabase
      .from("profile")
      .insert({ first_name: first, last_name: last, id: uuid });
    if (error) throw error;

    // Every user is an owner by default.
    const { data: ownerRes, error: insertOwnerError } = await supabase
      .from("owner")
      .insert({ user_id: uuid });
    if (insertOwnerError) throw error;

    return { error: null, data: { ...userRes, profileRes, ownerRes } };
  } catch (error) {
    console.error("Sign up failed", error);
    return { error };
  }
};

export const signOut = () => {
  supabase.auth.signOut();
};
