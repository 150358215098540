// TODO: Show sign up here if the user is not a crew member -

import { Link } from "react-router-dom";
import {
  getStatus,
  useAcceptedOffersForUserCrew,
  useOffer,
  useOffersForUserCrew,
} from "../shared/clients/offersClient";
import { isUnfinished } from "../shared/clients/voyagesClient";
import { Address } from "../shared/components/AddressDisplay";
import { Card } from "../shared/components/Card";
import { VoyageMap } from "../shared/components/VoyageMap";
import React, { useMemo } from "react";
import { UserName } from "../shared/components/UserName";
import { OfferStatusLabel } from "./OfferStatusLabel";
import { PageContent } from "../shared/components/Page";

type CrewDeliveryOfferCardByIdProps = {
  offerId: string;
};

export const CrewDeliveryOfferCardById = React.memo(
  (props: CrewDeliveryOfferCardByIdProps) => {
    const { data, error, status } = useOffer(props.offerId);
    if (status === "loading") {
      return null;
    }

    if (error) {
      console.error(error);
      return <div>Encountered an error loading offer.</div>;
    }

    if (!data) {
      return <div>No data...</div>;
    }

    return <CrewDeliveryOfferCard offer={data} />;
  }
);

type CrewDeliveryOfferCardProps = {
  offer: starboard.Offer;
};

export const CrewDeliveryOfferCard = React.memo(
  (props: CrewDeliveryOfferCardProps) => {
    const data = props.offer;
    return (
      <Card dataTestId={`offer-card-${data.id}`}>
        <VoyageMap voyage={data.voyage_crew_position.voyage} />
        <div style={{ padding: "0.5rem" }}>
          <label>Estimated Total Pay</label>
          <div
            style={{
              fontSize: "1.5rem",
              padding: "0.5rem",
              fontWeight: "bold",
              width: "fit-content",
              border: "solid 0.125rem var(--sb-green)",
              borderRadius: "0.5rem",
              background: "var(--sb-green-bg)",
              color: "var(--sb-green-color)",
            }}
          >
            ${data.price}
          </div>
          <label>Status</label>
          <OfferStatusLabel status={getStatus(data)} />
          <label>Route</label>
          <div>
            <Address value={data.voyage_crew_position.voyage.from_address} />
            <span
              style={{
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                fontWeight: "bold",
                color: "var(--sb-medium-dark-gray)",
              }}
            >
              {"->"}
            </span>
            <Address value={data.voyage_crew_position.voyage.to_address} />
          </div>
          <label>Requested At</label>
          <div>
            {data.created_at && new Date(data.created_at).toLocaleString()}
          </div>
          <label>Owner</label>
          <div>
            {data.owner.user_id && <UserName userId={data.owner.user_id} />}
          </div>
        </div>
      </Card>
    );
  }
);

export function CrewDeliveriesList() {
  const offersQuery = useOffersForUserCrew();
  const offers = offersQuery.data || [];
  const openOffers = offers.filter(
    (request) => request.accepted.length === 0
  ) as starboard.Offer[];

  const acceptedOffersQuery = useAcceptedOffersForUserCrew();
  const unfinishedVoyages = useMemo(
    () =>
      (acceptedOffersQuery?.data ?? []).filter(({ offer }) => {
        return isUnfinished(offer.voyage_crew_position.voyage);
      }),
    [acceptedOffersQuery.data]
  );

  if (
    acceptedOffersQuery.status === "loading" ||
    offersQuery.status === "loading"
  )
    return <Card>Loading...</Card>;

  return (
    <PageContent>
      {unfinishedVoyages.length === 0 && openOffers.length === 0 && (
        <Card>No deliveries yet.</Card>
      )}
      {unfinishedVoyages.map(({ offer }) => (
        <Link
          to={`deliveries/${offer.voyage_crew_position.voyage.id}`}
          key={offer.id}
          style={{ textDecoration: "none" }}
        >
          <CrewDeliveryOfferCard offer={offer} />
        </Link>
      ))}
      {openOffers.map((request) => (
        <Link
          to={`offers/${request.id}`}
          key={request.id}
          style={{ textDecoration: "none" }}
        >
          <CrewDeliveryOfferCard offer={request} />
        </Link>
      ))}
    </PageContent>
  );
}
