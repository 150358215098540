import create, { SetState } from "zustand";
import { Functionless } from "../helpers/typeHelpers";
import { selectCrewForDeliveryFromSearch } from "./crewClient";
import { lookup, reverseLookup } from "./mapkitClient";
import { Vessel } from "./vesselsClient";
import { persist } from "zustand/middleware";
import { CrewSearchParams } from "../../owner/CrewSearch";

const localStorageKey = `starboard-local-cache/search-form-state-${
  process.env.NODE_ENV || "dev"
}`;

// Don't trigger a map lookup on each keypress in crew search.
let timeout: number = 0;
const timeoutMs = 300;
export const fetchMapDetails = async (
  from: string | undefined,
  to: string | undefined
) => {
  console.log(`[searchmap] Fetching details for search: ${from} -> ${to}}`);
  try {
    // Our (from, to) data is still addresses so we have to look up coords.
    const [fromCoordinate, toCoordinate] = await Promise.all([
      from ? lookup(from) : undefined,
      to ? lookup(to) : undefined,
    ]);
    const [fromPlace, toPlace] = await Promise.all([
      fromCoordinate && reverseLookup(fromCoordinate),
      toCoordinate && reverseLookup(toCoordinate),
    ]);
    return {
      fromPlace,
      toPlace,
      fromCoordinate,
      toCoordinate,
    };
  } catch (e) {
    console.error(`[searchmap] Lookups failed for: ${from} -> ${to}`, e);
  }
};

const onSearchParamsChanged = (
  set: SetState<SearchFormState>,
  params: CrewSearchParams
) => {
  if (timeout) {
    window.clearTimeout(timeout);
  }

  if (params) {
    timeout = window.setTimeout(
      async () =>
        set({
          crewSearchMapDetails: await fetchMapDetails(params.from, params.to),
        }),
      timeoutMs
    );
  }
};

const setCrewSearchParams =
  (set: SetState<SearchFormState>) =>
  async (crewSearchParams: CrewSearchParams) => {
    set({
      crewSearchParams,
    });

    if (!crewSearchParams.from && !crewSearchParams.to) {
      set({
        crewSearchMapDetails: undefined,
        crewSearchResults: [],
      });
      return;
    }

    // Search and set crew results.
    console.log(`[browse] fetching with ${JSON.stringify(crewSearchParams)}`);
    const crewSearchResults = await selectCrewForDeliveryFromSearch(
      crewSearchParams
    );

    set({
      crewSearchResults: crewSearchResults?.data ?? [],
    });

    // Lookup and set map details.
    onSearchParamsChanged(set, crewSearchParams);
  };

interface SearchFormState {
  crewSearchParams: CrewSearchParams | undefined;
  crewSearchResults: starboard.tables["crew"][] | undefined;
  crewSearchMapDetails?: {
    fromPlace?: mapkit.Place | "";
    toPlace?: mapkit.Place | "";
    fromCoordinate?: mapkit.Coordinate;
    toCoordinate?: mapkit.Coordinate;
  };
  selectedCrew: starboard.tables["crew"] | undefined;
  selectedVessel: Vessel | undefined;
  setCrewSearchParams: (searchParams: CrewSearchParams) => void;
  selectCrew: (selectedCrew: starboard.tables["crew"]) => void;
  selectVessel: (selectedVessel: Vessel) => void;
  clearState: () => void;
}

const defaultSearchFormState: Functionless<SearchFormState> = {
  crewSearchParams: undefined,
  crewSearchMapDetails: undefined,
  crewSearchResults: undefined,
  selectedCrew: undefined,
  selectedVessel: undefined,
};

export const useSearchFormState = create(
  persist<SearchFormState>(
    (set) => ({
      ...defaultSearchFormState,
      selectCrew: (selectedCrew: starboard.tables["crew"]) =>
        set({ selectedCrew }),
      selectVessel: (selectedVessel: Vessel) => set({ selectedVessel }),
      setCrewSearchParams: setCrewSearchParams(set),
      clearState: () => set({ ...defaultSearchFormState }),
    }),
    {
      name: localStorageKey,
      deserialize: (stateStr: string) => {
        if (!stateStr) return { state: defaultSearchFormState };

        const stateJson = JSON.parse(stateStr) as {
          state: Partial<SearchFormState>;
        };
        return {
          state: {
            ...stateJson.state,
            crewSearchMapDetails: {
              ...stateJson.state.crewSearchMapDetails,
              toCoordinate: stateJson.state.crewSearchMapDetails?.toCoordinate
                ? new mapkit.Coordinate(
                    stateJson.state.crewSearchMapDetails?.toCoordinate.latitude,
                    stateJson.state.crewSearchMapDetails?.toCoordinate.longitude
                  )
                : undefined,
              fromCoordinate: stateJson.state.crewSearchMapDetails
                ?.fromCoordinate
                ? new mapkit.Coordinate(
                    stateJson.state.crewSearchMapDetails?.fromCoordinate.latitude,
                    stateJson.state.crewSearchMapDetails?.fromCoordinate.longitude
                  )
                : undefined,
            },
          },
        };
      },
      // partialize: (state) =>
      //   Object.fromEntries(
      //     Object.entries(state).filter(
      //       ([k]) => (k as keyof SearchFormState) !== "crewSearchMapDetails"
      //     )
      //   ),
    }
  )
);
