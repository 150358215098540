import { SetupIntent } from "@stripe/stripe-js";
import { PostgrestFilterBuilder } from "@supabase/postgrest-js";
import { useQuery } from "../helpers/reactHelpers";
import { fallbackOnNullQueryArg } from "../helpers/reactHelpers";
import { useUserOwner } from "./ownersClient";
import { supabase } from "./supabaseClient";
import { executeCommand } from "./supabaseHelpers";
import { useUserCrew, useUserCrewId } from "./crewClient";
import { useUserSessionData } from "./userClient";

const offerSelectString = `
*, 
owner!inner(user_id), 
accepted:offer_acceptance(*), 
rejected:offer_rejection(*), 
voyage_crew_position!inner(
  voyage!inner(
    id,
    created_at,
    to_address:to_address_id!inner(*),
    from_address:from_address_id!inner(*),
    vessel!inner(*),
    positions:voyage_crew_position (*, offers:offer (*)),
    starts:voyage_start(*),
    cancellations:voyage_cancellation(*),
    completions:voyage_completion(*)
  )
)
` as const;

const selectOffers = (): PostgrestFilterBuilder<any, any, starboard.Offer> => {
  return supabase.from("offer").select(offerSelectString);
};

const selectAcceptedOffersForCrewId = fallbackOnNullQueryArg(
  (crewId: number) => {
    return supabase
      .from("offer_acceptance")
      .select(`offer!inner(${offerSelectString})`)
      .eq(`${"offer"}.crew_id`, crewId) as unknown as PostgrestFilterBuilder<
      any,
      any,
      { offer: starboard.Offer }
    >;
  }
);

const selectOffer = fallbackOnNullQueryArg((offerId: string) => {
  return selectOffers().eq("id", offerId).single();
});

const selectOffersForOwnerId = fallbackOnNullQueryArg((ownerId: number) => {
  return selectOffers()
    .eq("owner_id", ownerId)
    .order("created_at", { ascending: false });
});

const selectOffersForCrewId = fallbackOnNullQueryArg((crewId: number) => {
  return selectOffers()
    .eq("crew_id", crewId)
    .order("created_at", { ascending: false });
});

export const makeOffer = (payload: starboard.MakeOfferCommandPayload) =>
  executeCommand("offers/make", payload);

/**
 * Inserts a rejection record for this offer.
 *
 * @param id
 *
 * @todo - Need a way to prevent multiple to be inserted at a time,
 * and constraints so that a rejection and an approval cannot exist
 * at the same time.
 */
export const rejectOffer = async (id: number) => {
  console.log(`rejecting offer ${id}`);
  try {
    let { error, status } = await supabase.from("offer_rejection").insert({
      offer_id: id,
    });

    if (error && status !== 406) {
      throw error;
    }
    console.log(`offer rejection successful`);
    return { error: null };
  } catch (error) {
    console.error(error);
    return { error };
  }
};

/**
 * Inserts a rejection record for this offer.
 *
 * @todo - Need a way to prevent multiple to be inserted at a time,
 * and constraints so that a rejection and an approval cannot exist
 * at the same time.
 */
export const acceptOffer = async (id: number) => {
  console.log(`accepting offer ${id}`);
  try {
    const { data: paymentResponse, error: paymentError } =
      await executeCommand<{ paymentIntent: any; stripePubKey: string }>(
        "offers/accept",
        { offerId: id }
      );
    if (paymentError || !paymentResponse)
      throw paymentError || new Error("No data");
    // It seems a function can return no data and not have thrown an error,
    // this could be fixed in later versions of supabase.
    if (Object.keys(paymentResponse).length === 0) throw new Error("No data.");

    console.log(
      `[offers] Received payment intent ${paymentResponse.paymentIntent.id}.`
    );

    let { error } = await supabase.from("offer_acceptance").insert({
      offer_id: id,
    });

    if (error) throw error;
    console.log(`[offers] Inserted acceptance. Offer flow complete.`);

    return {
      error: null,
    };
  } catch (error) {
    console.error(`[offers] Accept failed:`, error);
    return { error };
  }
};

interface GetStripeCustomerResponse {
  setupIntent: SetupIntent;
  ephemeralKey: string;
  customer: string;
  stripe_pk: string;
}

export const getSetupIntent = fallbackOnNullQueryArg((_userId: string) =>
  executeCommand<GetStripeCustomerResponse>("offers/prepare")
);

export const useOffersForUserOwner = () => {
  const userOwnerQuery = useUserOwner();
  const userOwnerId = userOwnerQuery?.data?.id;
  const result = useQuery(selectOffersForOwnerId, [userOwnerId]);

  return result.data || [];
};

export const useOffersForUserCrew = () => {
  const crewIdQuery = useUserCrewId();
  return useQuery(selectOffersForCrewId, [crewIdQuery]);
};

export const useAcceptedOffersForUserCrew = () => {
  const crew = useUserCrew();
  const crewId = crew?.data?.id;

  return useQuery(selectAcceptedOffersForCrewId, [crewId]);
};

export const useSetupIntentForUser = () => {
  const userSession = useUserSessionData();
  const userId = userSession?.user.id;
  return useQuery(getSetupIntent, [userId]);
};

export const useOffer = (offerId: string | undefined) => {
  return useQuery(selectOffer, [offerId]);
};

export const useOfferIdsForUserCrew = () => {
  return useQuery(selectOffersForCrewId, [useUserCrewId()]);
};

export const OfferStatus = {
  open: "open",
  rejected: "rejected",
  accepted: "accepted",
} as const;

export type OfferStatusValue = (typeof OfferStatus)[keyof typeof OfferStatus];

export const getStatus = (offer: starboard.Offer): OfferStatusValue => {
  if (offer.accepted.length) {
    return OfferStatus.accepted;
  }

  if (offer.rejected.length) {
    return OfferStatus.rejected;
  }

  return OfferStatus.open;
};
