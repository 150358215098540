import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App, {
  AppPageHeader,
  AppPageHeaderWithBackButton,
  CrewAppPageHeader,
  CrewAppPageHeaderWithBack,
} from "./shared/components/App";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Account from "./shared/components/Account";
import { Offer } from "./crew/Offer";
import { CrewSearchBrowseStep } from "./owner/CrewSearchBrowseStep";
import {
  CrewSearchNewVesselStep,
  CrewSearchPickVesselStep,
} from "./owner/CrewSearchVesselStep";
import { CrewSearchReviewStep } from "./owner/CrewSearchReviewStep";
import { Enlist } from "./crew/Enlist";
import { BottomTabContainer } from "./shared/components/BottomTabContainer";
import { BottomTab } from "./shared/components/BottomTab";
import { FallbackToSignup } from "./crew/Enlist";
import { CrewSearch } from "./owner/CrewSearch";
import { VoyagesList } from "./owner/OwnerDeliveries";
import { CrewSearchContainer } from "./owner/CrewSearchContainer";
import { SearchIcon, TripIcon } from "./shared/icons";
import { CrewDeliveriesList } from "./crew/CrewDeliveries";
import { DeliveryPage } from "./crew/Bridge";

const rootEl = document.getElementById("root");
if (!rootEl) throw new Error("No root element found.");

const root = ReactDOM.createRoot(rootEl);
root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/*"
        element={
          <App
            header={
              <Routes>
                <Route
                  path="owner/search/*"
                  element={<AppPageHeaderWithBackButton />}
                />
                <Route
                  path="account"
                  element={<AppPageHeaderWithBackButton />}
                />
                <Route path="owner/search" element={<AppPageHeader />} />
                <Route path="crew/*" element={<CrewAppPageHeader />} />
                <Route
                  path="crew/deliveries/:deliveryId"
                  element={<CrewAppPageHeaderWithBack />}
                />
                <Route
                  path="crew/offers/:offerId"
                  element={<CrewAppPageHeaderWithBack />}
                />
                <Route path="*" element={<AppPageHeader />} />
              </Routes>
            }
          />
        }
      >
        <Route path="enlist" element={<Enlist />}></Route>
        <Route path="crew/deliveries/:deliveryId" element={<DeliveryPage />} />
        <Route path="crew/offers/:offerId" element={<Offer />} />
        <Route path="account" element={<Account />} />
        <Route
          path="crew/*"
          element={
            <BottomTabContainer
              label={
                <Routes>
                  <Route
                    path="/"
                    element={<h3 className="page-row">Deliveries</h3>}
                  />
                </Routes>
              }
              tabs={[
                <BottomTab key="crew-deliveries-tab" to="deliveries">
                  <TripIcon
                    style={{ height: "1rem", marginRight: "0.25rem" }}
                  />
                  Deliveries
                </BottomTab>,
              ]}
            />
          }
        >
          <Route
            path="*"
            element={
              <FallbackToSignup>
                <CrewDeliveriesList />
              </FallbackToSignup>
            }
          />
        </Route>
        <Route
          path="owner/*"
          element={
            <BottomTabContainer
              label={
                <Routes>
                  <Route
                    path="search"
                    element={<h3 className="page-row">Setup a Delivery</h3>}
                  />
                  <Route
                    path="deliveries"
                    element={<h3 className="page-row">Deliveries</h3>}
                  />
                  <Route
                    path="search/vessel"
                    element={<h3 className="page-row">Vessel</h3>}
                  />
                  <Route
                    path="search/vessel/create"
                    element={<h3 className="page-row">Vessel</h3>}
                  />
                </Routes>
              }
              tabs={[
                <BottomTab key="owner-search-tab" to="search">
                  <SearchIcon
                    style={{ height: "1rem", marginRight: "0.25rem" }}
                  />
                  Search
                </BottomTab>,
                <BottomTab key="owner-deliveries-tab" to="deliveries">
                  <TripIcon
                    style={{ height: "1rem", marginRight: "0.25rem" }}
                  />
                  Deliveries
                </BottomTab>,
              ]}
            />
          }
        >
          <Route path="search" element={<CrewSearchContainer />}>
            <Route index element={<CrewSearch />}></Route>
            <Route path="browse" element={<CrewSearchBrowseStep />} />
            <Route path="vessel">
              <Route index element={<CrewSearchPickVesselStep />} />
              <Route path="create" element={<CrewSearchNewVesselStep />} />
            </Route>
            <Route path="review" element={<CrewSearchReviewStep />} />
          </Route>
          <Route path="deliveries" element={<VoyagesList />} />
        </Route>
        <Route path="*" element={<Navigate to="owner/search" />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
