import React from "react";
import { Link } from "react-router-dom";
import { useUserProfile } from "../clients/userClient";

export const UserLabel: React.FC<{}> = React.memo(function UserLabelInner() {
  const profileQuery = useUserProfile();
  if (!profileQuery?.data) {
    return null;
  }

  const accountLabel = `${profileQuery.data.first_name} ${profileQuery.data.last_name}`;
  return <span>{accountLabel}</span>;
});

export const UserLink: React.FC<{ style: React.CSSProperties }> = (props) => {
  return (
    <Link
      to="/account"
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "var(--sb-blue-0)",
        fontSize: "0.75rem",
        ...props.style,
      }}
    >
      <UserLabel />
    </Link>
  );
};
