import { useEffect, useState } from "react";
import { supabase } from "../clients/supabaseClient";
import { selectProfileForUser } from "../clients/userClient";
import { fallbackOnNullQueryArg, useQuery } from "../helpers/reactHelpers";

// Displays a name span given a user id. Looks up the user's profile to do so.
export const UserName: React.FC<{ userId?: string }> = (props) => {
  const { userId } = props;
  const [profile, setProfile] = useState<starboard.tables["profile"]>();
  useEffect(() => {
    if (!userId) return;

    const doEffect = async () => {
      try {
        const { data } = await selectProfileForUser(userId);
        if (data) setProfile(data);
      } catch (e) {
        console.error(e);
      }
    };

    doEffect();
  }, [userId]);

  if (!profile) return <span>...</span>;
  return (
    <span>{`${profile.first_name || ""} ${profile.last_name || ""}`}</span>
  );
};

const queryCrewId = fallbackOnNullQueryArg((crewId: number) =>
  supabase.from("crew").select("user_id").eq("id", crewId).single()
);

export const CrewName: React.FC<{ crewId?: number | null }> = ({ crewId }) => {
  const crew = useQuery(queryCrewId, [crewId]);
  if (!crew) return <span>...</span>;
  return <UserName userId={crew.data?.user_id} />;
};
