import "../index.css";
import { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { CrewSearchMap } from "./CrewSearchMap";
import { Card } from "../shared/components/Card";
import { AddressInput } from "../shared/components/AddressInput";
import { RouteName } from "../routes";
import { useSearchFormState } from "../shared/clients/searchForm";
import { debounce } from "../shared/helpers/reactHelpers";

export interface CrewSearchParams {
  to: string;
  from: string;
}

export function CrewSearch() {
  const navigate = useNavigate();
  const { setCrewSearchParams, crewSearchParams } = useSearchFormState(
    (state) => state
  );

  const onSubmitCrewSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.preventDefault();
    const formEntries = Array.from(new FormData(event.currentTarget).entries());
    const crewSearchParams = Object.fromEntries(
      formEntries
    ) as unknown as CrewSearchParams;

    console.log(`[browse] Submitted ${JSON.stringify(crewSearchParams)}`);
    setCrewSearchParams(crewSearchParams);
    navigate(RouteName.crewSearch);
  };

  // The map reacts to changes so we want to prevent thrashing.
  const onChangeCrewSearch: React.ChangeEventHandler<HTMLFormElement> =
    debounce(
      (e) =>
        setCrewSearchParams({
          from: crewSearchParams?.from ?? "",
          to: crewSearchParams?.to ?? "",
          [e.target.name]: e.target.value,
        }),
      1000
    );

  return (
    <Card style={{ height: "100%", marginBottom: 0 }}>
      <form
        onSubmit={onSubmitCrewSearch}
        onChange={onChangeCrewSearch}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <CrewSearchMap style={{ flex: 2 }}></CrewSearchMap>
        <div>
          <AddressInput
            id="from-address-input"
            name="from"
            style={{ height: "2.5rem", marginTop: "0.5rem" }}
            defaultValue={crewSearchParams?.from}
            placeholder="Enter pickup location"
          ></AddressInput>
          <AddressInput
            id="to-address-input"
            name="to"
            style={{ height: "2.5rem", marginTop: "0.5rem" }}
            defaultValue={crewSearchParams?.to}
            placeholder="Enter destination"
          ></AddressInput>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <input
              type="submit"
              value="Find a Captain"
              style={{
                backgroundColor: "var(--sb-yellow)",
                fontWeight: "bold",
                fontSize: "1rem",
                border: 0,
                padding: "1rem",
                display: "block",
                marginTop: "0.5rem",
              }}
            ></input>
          </div>
        </div>
      </form>
    </Card>
  );
}
