import { useNavigate, useParams } from "react-router-dom";
import { acceptOffer, rejectOffer } from "../shared/clients/offersClient";
import { CrewDeliveryOfferCardById } from "./CrewDeliveries";
import { PageContent } from "../shared/components/Page";

/**
 * Offer page for crew to accept or deny an offer for hire
 * by a vessel owner.
 *
 * Note: Acceptance triggers payment of the owner's card to
 * starboard.
 */
export const Offer: React.FC<{}> = () => {
  const { offerId } = useParams();
  const navigate = useNavigate();

  const onAccept = async () => {
    if (!offerId) return;
    const offerIdParsed = parseInt(offerId);
    if (isNaN(offerIdParsed)) return;

    const { error } = await acceptOffer(offerIdParsed);
    /**
     * @todo
     * Notify of error in UI? Not sure what the user is supposed to do
     * aside from report the problem to Starboard, but they are stuck
     * in this screen if the error is persistent.
     */
    if (!error) navigate(-1);
  };

  const onReject = async () => {
    if (!offerId) return;
    const offerIdParsed = parseInt(offerId);
    if (isNaN(offerIdParsed)) return;

    const { error } = await rejectOffer(offerIdParsed);
    /**
     * @todo
     * Notify of error in UI? Not sure what the user is supposed to do
     * aside from report the problem to Starboard, but they are stuck
     * in this screen if the error is persistent.
     */
    if (!error) navigate(-1);
  };

  if (!offerId) {
    /**
     * @todo
     * Fallback to an actual 404 page.
     */
    return <div>404</div>;
  }

  return (
    <PageContent
      style={{
        display: "grid",
        gridTemplateRows: "1fr 4rem",
        gridRowGap: "0.5rem",
      }}
    >
      <CrewDeliveryOfferCardById offerId={offerId} />
      <div
        style={{
          gridRow: "2",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            backgroundColor: "var(--sb-dark-gray)",
            fontWeight: "bold",
            color: "var(--sb-lightest-gray)",
            padding: "1rem",
            width: "45%",
          }}
          onClick={onReject}
        >
          No Thanks
        </button>
        <button
          style={{
            backgroundColor: "var(--sb-yellow)",
            fontWeight: "bold",
            color: "var(--sb-dark-gray)",
            padding: "1rem",
            width: "45%",
          }}
          onClick={onAccept}
        >
          Accept
        </button>
      </div>
    </PageContent>
  );
};
