import { PropsWithChildren, ReactElement } from "react";
import { Outlet } from "react-router-dom";
import { PageTabbedContentFrame } from "./Page";

export function BottomTabContainer(
  props: PropsWithChildren<{ tabs: ReactElement[]; label: ReactElement }>
) {
  return (
    <PageTabbedContentFrame tabs={props.tabs} label={props.label}>
      <Outlet />
    </PageTabbedContentFrame>
  );
}
