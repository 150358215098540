import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "./Avatar";
import { CrewSignupLinkCard } from "../../crew/Enlist";
import { UserLabel } from "./UserLink";
import { useUserCrew } from "../clients/crewClient";
import { signOut, useUserSessionData } from "../clients/userClient";
import { PageContent } from "./Page";

/**
 * The user's account page. This covers both their owner and crew profiles.
 */
const Account = () => {
  const navigate = useNavigate();
  const crew = useUserCrew();
  const session = useUserSessionData();
  const email = session?.user?.email;

  return (
    <PageContent>
      <div
        style={{
          marginTop: "0.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar size={"33vmin"} />
        <div style={{ flex: 1 }}>
          <h2 style={{ marginBottom: "0.125rem", textAlign: "center" }}>
            <UserLabel />
          </h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Link
              to="/crew"
              style={{
                padding: "0.5rem",
                marginTop: "0.125rem",
                color: "var(--sb-color-quiet)",
                textAlign: "center",
              }}
            >
              Crew Home
            </Link>
            <Link
              to="/owner/search"
              style={{
                padding: "0.5rem",
                marginTop: "0.125rem",
                color: "var(--sb-color-quiet)",
                textAlign: "center",
              }}
            >
              Owner Home
            </Link>
            <button
              type="button"
              style={{
                marginTop: "0.125rem",
                background: "transparent",
                color: "var(--sb-color-quiet)",
                fontSize: "1rem",
              }}
              onClick={() => {
                signOut();
                navigate("/");
              }}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
      <section>
        <h4>User</h4>
        <form className="form-widget">
          <div>
            <label htmlFor="email">Email</label>
            <input id="email" type="email" value={email ?? ""} readOnly />
          </div>
        </form>
      </section>
      <section>
        <h4>Crew</h4>
        {!crew?.data && <CrewSignupLinkCard />}
        {crew?.data && (
          <>
            <form className="form-widget">
              <div>
                <label htmlFor="home_address_as_written">Home Address</label>
                <input
                  id="home_address_as_written"
                  type="text"
                  value={crew?.data?.home_address_as_written ?? ""}
                  readOnly
                />
              </div>
            </form>
          </>
        )}
      </section>
    </PageContent>
  );
};

export default Account;
