import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserOwner } from "../shared/clients/ownersClient";
import { supabase } from "../shared/clients/supabaseClient";
import { useOwnerVessels, Vessel } from "../shared/clients/vesselsClient";
import { VesselAvatar } from "../shared/components/Avatar";
import { Card } from "../shared/components/Card";
import { useSearchFormState } from "../shared/clients/searchForm";
type VesselFormProps = {
  // Called when the submission is complete.
  onSubmitted: (vessel: Vessel) => unknown;
};
const VesselForm = ({ onSubmitted }: VesselFormProps) => {
  const owner = useUserOwner();
  const ownerId = owner.data?.id;
  const handleSubmit: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const formData = Object.fromEntries(
          new FormData(e.currentTarget).entries()
        );

        // Convince compiler that these fields are not Files.
        if (typeof formData.length !== "string") {
          throw new Error("Invalid length type: " + typeof formData.length);
        }

        if (typeof formData.name !== "string") {
          throw new Error("Invalid name type: " + typeof formData.name);
        }

        if (typeof formData.builder !== "string") {
          throw new Error(
            "Invalid builder field type: " + typeof formData.builder
          );
        }

        if (typeof formData.year !== "string") {
          throw new Error("Invalid year field type: " + typeof formData.year);
        }

        if (typeof ownerId !== "number") {
          throw new Error("Invalid ownerId field type." + typeof ownerId);
        }

        const { data, error } = await supabase
          .from("vessel")
          .insert({
            owner_id: ownerId,
            name: formData.name,
            builder: formData.builder,
            length: parseFloat(formData.length),
            year_built: parseInt(formData.year),
          })
          .select()
          .single();

        if (error) {
          console.error(error);
          return;
        }
        console.log("Submitted ID " + data.id);
        onSubmitted(data);

        // The image file is stored separately
        const file = formData.image;
        if (!(file instanceof File) || file.size === 0) {
          console.error("Invalid image file");
          return;
        }

        const fileExt = file.name.split(".").pop();
        const uniquePrefix = Date.now(); // TODO : Replace with user id?
        const fileName = `${uniquePrefix}.${fileExt}`;
        const filePath = `${fileName}`;

        let { error: uploadError } = await supabase.storage
          .from("avatars")
          .upload(filePath, file, { upsert: true });

        if (uploadError) {
          console.error(
            "Create vessel failed to upload avatar image to storage",
            uploadError
          );
          return;
        }

        const { error: setUrlError } = await supabase
          .from("vessel")
          .update({ image_url: filePath })
          .eq("id", data.id);

        if (setUrlError) {
          console.error(
            "Create vessel failed to set avatar link on vessel",
            setUrlError
          );
          return;
        }
      },
      [onSubmitted, ownerId]
    );

  if (!ownerId) {
    return <span>Loading ...</span>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name
        <input type="text" maxLength={255} name="name"></input>
      </label>
      <label>
        Length (ft)
        <input type="number" name="length"></input>
      </label>
      <label>
        Builder
        <input type="text" name="builder"></input>
      </label>
      <label>
        Year Built
        {/* TODO: Make this a better year input, not allowing decimals, maybe a dropdown, etc. */}
        <input type="number" min="0" step="1" name="year" />
      </label>
      <label>
        Image
        <input name="image" type="file" accept="image/*" />
      </label>
      <input
        type="submit"
        value="Add Vessel"
        style={{
          backgroundColor: "var(--sb-yellow)",
          fontWeight: "bold",
          fontSize: "1rem",
          padding: "1rem",
          display: "block",
          marginTop: "0.5rem",
          cursor: "pointer",
        }}
      />
    </form>
  );
};

/**
 * Allows crew searcher to select vessel being worked on.
 * TODO: Automatically launch create new vessel flow if no vessels are found.
 */
export const CrewSearchPickVesselStep: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const ownerVesselsQuery = useOwnerVessels();
  const { selectVessel } = useSearchFormState();
  const vessels = ownerVesselsQuery.data || [];

  if (ownerVesselsQuery.status === "loading") {
    return (
      <div>
        <span>Loading...</span>
      </div>
    );
  }
  return (
    <div>
      {vessels.length ? (
        <div>
          {vessels.map((vessel, index) => (
            <Card
              key={vessel.id}
              onClick={() => {
                selectVessel(vessel);
                navigate("/owner/search/review");
              }}
              style={{
                marginTop: index === 0 ? "unset" : "0.5rem",
                display: "grid",
                gridTemplateRows: "1fr",
                gridTemplateColumns: "4rem 1fr 6rem",
                fontWeight: "bold",
                fontSize: "1.25rem",
                gridGap: "0.5rem",
                cursor: "pointer",
              }}
            >
              <VesselAvatar vesselId={vessel.id} size={64} />
              <div style={{ display: "flex", alignItems: "center" }}>
                {vessel.name}
              </div>
            </Card>
          ))}
          <Card
            style={{
              marginTop: "0.5rem",
              display: "grid",
              gridTemplateRows: "1fr",
              gridTemplateColumns: "4rem 1fr 6rem",
              fontWeight: "bold",
              fontSize: "1.25rem",
              gridGap: "0.5rem",
              cursor: "pointer",
              color: "var(--sb-medium-dark-gray)",
            }}
            onClick={() => navigate("create")}
          >
            <div
              style={{
                height: "64px",
                width: "64px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--sb-light-gray)",
                borderRadius: "0.5rem",
                color: "var(--sb-medium-gray)",
              }}
            >
              +
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "var(--sb-gray-2)",
              }}
            >
              Add New Vessel
            </div>
          </Card>
        </div>
      ) : (
        <div>
          <Card
            style={{
              marginTop: "0.5rem",
              display: "grid",
              gridTemplateRows: "1fr",
              gridTemplateColumns: "4rem 1fr 6rem",
              fontWeight: "bold",
              fontSize: "1.25rem",
              gridGap: "0.5rem",
              cursor: "pointer",
              color: "var(--sb-dark-gray)",
            }}
            onClick={() => navigate("create")}
          >
            <div
              style={{
                height: "64px",
                width: "64px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "var(--sb-light-gray)",
                borderRadius: "0.5rem",
              }}
            >
              +
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "var(--sb-gray-2)",
              }}
            >
              Add New Vessel
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export const CrewSearchNewVesselStep: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { selectVessel } = useSearchFormState();

  return (
    <Card>
      <VesselForm
        onSubmitted={(vessel) => {
          selectVessel(vessel);
          navigate("/owner/search/review");
        }}
      />
    </Card>
  );
};
