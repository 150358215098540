import { CSSProperties, PropsWithChildren, ReactElement } from "react";

/**
 * Page framing components.
 */

export const Page: React.FC<PropsWithChildren<{ style?: CSSProperties }>> = ({
  children,
}) => {
  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "100%",
        position: "relative",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const PageHeader: React.FC<
  PropsWithChildren<{ style?: CSSProperties }>
> = ({ children }) => {
  return (
    <header
      className="page-row"
      style={{
        zIndex: 100,
        paddingTop: "1rem",
        paddingBottom: "0.5rem",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        fontSize: "0.75rem",
        maxWidth: "100%",
        // minHeight: "4rem",
      }}
    >
      {children}
    </header>
  );
};

export const PageContent: React.FC<
  PropsWithChildren<{ style?: CSSProperties }>
> = ({ children, style }) => {
  return (
    <div
      style={{
        padding: "0.5rem",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const PageTabbedContentFrame: React.FC<
  PropsWithChildren<{ tabs: ReactElement[]; label: ReactElement }>
> = ({ children, tabs, label }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div style={{}}>{label}</div>
      <div style={{ flex: "1", overflowY: "auto" }}>{children}</div>
      <div
        style={{
          height: "4rem",
          display: "grid",
          gridTemplateColumns: tabs.map(() => "1fr").join(" "),
          borderTop: "solid 1px var(--sb-medium-gray)",
          background: "var(--sb-lightest-gray)",
        }}
      >
        {tabs}
      </div>
    </div>
  );
};
