import { FormEvent, forwardRef, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

interface CheckoutFormProps {
  onSuccessfulCheckout: () => void;
}

/**
 * @see https://stripe.com/docs/payments/save-and-reuse?html-or-react=react
 */
const SetupForm = forwardRef<HTMLFormElement, CheckoutFormProps>(
  (props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event: FormEvent) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        if (error.message) {
          setErrorMessage(error.message);
        }
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        props.onSuccessfulCheckout();
      }
    };

    return (
      <form ref={ref} onSubmit={handleSubmit}>
        <PaymentElement />
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    );
  }
);

export default SetupForm;
