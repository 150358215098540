import React, { useCallback, useRef } from "react";
import { useMemo } from "react";
import { useSearchFormState } from "../shared/clients/searchForm";
import {
  addRouteAnnotation,
  isCoordinateEqual,
  useMap,
  useUserCoordinate,
} from "../shared/components/useMap";
import { debounce } from "../shared/helpers/reactHelpers";

const CREW_SEARCH_MAP_CONTAINER_ID = "crew-search-map-container";

interface CrewSearchMapProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export function CrewSearchMap(props: CrewSearchMapProps) {
  const { crewSearchMapDetails } = useSearchFormState();
  const from = crewSearchMapDetails?.fromCoordinate;
  const to = crewSearchMapDetails?.toCoordinate;
  const map = useMap(CREW_SEARCH_MAP_CONTAINER_ID);

  // TODO : Make into a generic annotation set, which removes annotations
  // that leave the set.
  const prevFrom = useRef<mapkit.MarkerAnnotation>();
  const prevTo = useRef<mapkit.MarkerAnnotation>();
  const prevOverlay = useRef<mapkit.PolylineOverlay>();

  const handleChangeCoordinates = useCallback(
    (
      from: mapkit.Coordinate | undefined,
      to: mapkit.Coordinate | undefined
    ) => {
      if (!map) {
        console.log("No map, not annotating route.");
        return;
      }

      if (
        isCoordinateEqual(from, prevFrom.current?.coordinate) &&
        isCoordinateEqual(to, prevTo.current?.coordinate)
      ) {
        return;
      }

      if (prevFrom.current) map.removeAnnotation(prevFrom.current);
      if (prevTo.current) map.removeAnnotation(prevTo.current);
      if (prevOverlay.current) map.removeOverlay(prevOverlay.current);

      const [nextFrom, nextTo, nextOverlay] = addRouteAnnotation(map, from, to);
      prevFrom.current = nextFrom;
      prevTo.current = nextTo;
      prevOverlay.current = nextOverlay;
    },
    [map]
  );

  const handleChangeCoordinatesDebounced = useMemo(
    () => debounce(handleChangeCoordinates, 250),
    [handleChangeCoordinates]
  );

  useMemo(
    () => handleChangeCoordinatesDebounced(from, to),
    [handleChangeCoordinatesDebounced, from, to]
  );

  const uCoord = useUserCoordinate();
  useMemo(() => {
    if (!map) {
      return;
    }

    if (from || to) {
      return;
    }

    if (!uCoord) {
      return;
    }

    map.setCenterAnimated(uCoord);
  }, [from, map, to, uCoord]);

  return (
    <div
      {...props}
      id={CREW_SEARCH_MAP_CONTAINER_ID}
      style={{ height: "100%", ...props.style }}
    ></div>
  );
}
