import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { PostgrestTransformBuilder } from "@supabase/postgrest-js";
import { supabase } from "./supabaseClient";

/**
 * A helper for inserting a record. Makes sure the inserted record is of
 * the correct type.
 *
 * It is overloaded to return a single result when given a single record.
 */
export function insert<T extends keyof starboard.tables>(
  tableName: T,
  record: starboard.db["public"]["Tables"][T]["Insert"]
): PromiseLike<PostgrestSingleResponse<starboard.tables[T]>>;
export function insert<T extends keyof starboard.tables>(
  tableName: T,
  record: starboard.db["public"]["Tables"][T]["Insert"][]
): PostgrestTransformBuilder<any, any, starboard.tables[T]>;
export function insert<T extends keyof starboard.tables>(
  tableName: T,
  record:
    | starboard.db["public"]["Tables"][T]["Insert"][]
    | starboard.db["public"]["Tables"][T]["Insert"]
):
  | PostgrestTransformBuilder<any, any, starboard.tables[T]>
  | PromiseLike<PostgrestSingleResponse<starboard.tables[T]>> {
  if (Array.isArray(record))
    return supabase
      .from(tableName as string)
      .insert(record)
      .select();
  return supabase
    .from(tableName as string)
    .insert(record)
    .select()
    .single();
}

export function executeCommand<
  ResponsePayload,
  Payload extends Record<string, unknown> = {}
>(name: string, payload?: Payload) {
  try {
    return supabase.functions.invoke<ResponsePayload>("uber", {
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        type: name,
        payload,
      }),
    });
  } catch (error) {
    console.error(error);
    return {
      data: null,
      error: error instanceof Error ? error : new Error("Unknown error"),
    };
  }
}
