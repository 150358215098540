import React from "react";
import { Link } from "react-router-dom";

export const AppNavButton: React.FC<
  React.ButtonHTMLAttributes<{}> & { to?: string }
> = ({ style, children, to, ...rest }) => {
  if (to) {
    return (
      <Link
        to={to}
        style={{
          color: "var(--sb-blue-0)",
          backgroundColor: "transparent",
          textAlign: "left",
          fontSize: "inherit",
          alignSelf: "end",
          ...style,
        }}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      style={{
        color: "var(--sb-blue-0)",
        backgroundColor: "transparent",
        textAlign: "left",
        fontSize: "inherit",
        alignSelf: "end",
        ...style,
      }}
      {...rest}
    >
      {children}
    </button>
  );
};
