import React, { useMemo } from "react";
import { fallbackOnNullArg } from "./typeHelpers";
import {
  PostgrestMaybeSingleResponse,
  PostgrestResponse,
  PostgrestSingleResponse,
} from "@supabase/supabase-js";

const compare = (a: any[] | undefined, b: any[] | undefined): boolean =>
  !a || !b || a.length !== b.length || a.some((v, i) => v !== b[i]);

export function fallbackOnNullQueryArg<Args extends any[], Result>(
  strictFunc: (...args: Args) => Result
) {
  return fallbackOnNullArg(strictFunc, nullArgResult);
}

export const queryStatus = {
  complete: "complete",
  loading: "loading",
} as const;

export const loadingResult = {
  data: null,
  error: null,
  status: queryStatus.loading,
} as const;

const nullArgResult = {
  data: null,
  error: {
    message: "Missing required query arg.",
  },
  status: queryStatus.complete,
} as const;

type ClientErrorResult = {
  data: null;
  error: {
    message: string;
  };
  status: "complete";
};

export type AllQueryResult<Datum> =
  | PostgrestResponse<Datum>
  | PostgrestSingleResponse<Datum>
  | PostgrestMaybeSingleResponse<Datum>
  | ServerQueryResult<Datum>
  | typeof loadingResult
  | typeof nullArgResult
  | ClientErrorResult;

export type ServerQueryResult<Datum> =
  | { data: Datum; error: null }
  | { data: null; error: Error };

export type QueryResult<Return> =
  | Awaited<Return>
  | ClientErrorResult
  | typeof loadingResult;

type ArgOrArgQuery<Arg> = QueryResult<AllQueryResult<Arg>> | Arg;
type ArgsOrArgQueries<Args extends any[]> = Args extends (infer Arg)[]
  ? ArgOrArgQuery<Arg>[]
  : never;

export function useQuery<Args extends any[], Result>(
  query: (...args: Args) => Result,
  deps: ArgsOrArgQueries<Args>
): QueryResult<Result> {
  // The current response.
  const [response, setResponse] =
    React.useState<QueryResult<Result>>(loadingResult);

  // Tracked to prevent duplicates.
  const inFlightDeps = React.useRef<any[]>();

  React.useEffect(() => {
    async function doQuery(withArgs: ArgsOrArgQueries<Args>) {
      inFlightDeps.current = withArgs;
      try {
        let isArgQueryLoading = false;
        const argData = withArgs.map((argOrArgQuery) => {
          if (
            argOrArgQuery &&
            typeof argOrArgQuery === "object" &&
            "data" in argOrArgQuery
          ) {
            isArgQueryLoading =
              isArgQueryLoading || argOrArgQuery.status === "loading";
            return argOrArgQuery.data;
          }
          return argOrArgQuery;
        });

        if (isArgQueryLoading) {
          setResponse(loadingResult);
          return;
        }

        const result = await query(...(argData as Args));
        if (!compare(withArgs, inFlightDeps.current)) {
          // The args for this flight are the latest.
          setResponse(result);
        }
      } catch (error) {
        if (
          !compare(withArgs, inFlightDeps.current) &&
          error instanceof Error
        ) {
          // The args for this flight are the latest.
          setResponse({
            data: null,
            error: { message: error.message },
            status: queryStatus.complete,
          });
        }
      }

      inFlightDeps.current = undefined;
    }
    if (compare(deps, inFlightDeps.current)) {
      doQuery(deps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return response;
}

export function debounce<A extends any[], R = void>(
  fn: (...args: A) => R,
  ms: number
): (...args: A) => Promise<R> {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (...args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(...args));
      }, ms);
    });

  // Can add to make cancelable.
  // const teardown = () => clearTimeout(timer);

  return debouncedFunc; //[debouncedFunc, teardown];
}

export const subQuery = <
  ParentQuery extends AllQueryResult<ParentDatum>,
  ParentDatum,
  Selection
>(
  parent: ParentQuery,
  select: (parent: ParentQuery) => Selection
): QueryResult<PostgrestMaybeSingleResponse<Selection>> => {
  return {
    ...parent,
    data: parent.data === null ? null : select(parent),
  } as QueryResult<PostgrestMaybeSingleResponse<Selection>>;
};

export const useSubQuery = <
  ParentQuery extends AllQueryResult<ParentDatum>,
  ParentDatum,
  Selection
>(
  parent: ParentQuery,
  select: (parent: ParentQuery) => Selection
) => useMemo(() => subQuery(parent, select), [parent, select]);
