import { FormEvent, useState } from "react";
import { supabase } from "../clients/supabaseClient";
import { signUp } from "../clients/userClient";
import { SignInWithGoogleButton } from "./SignInWIthGoogleButton";

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
    } catch (error: any) {
      console.error(error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await signUp({ email, password, name });
      if (error) throw error;
    } catch (error: any) {
      console.error(error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
      });
      if (error) throw error;
    } catch (error: any) {
      console.error(error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row flex flex-center">
      <div className="col-6 form-widget" aria-live="polite" style={{ gap: 0 }}>
        <h1 className="header">Starboard</h1>
        {!isSigningUp && (
          <>
            <form id="sign-in-form" onSubmit={handleLogin}>
              <label>
                email
                <input
                  id="email"
                  type="email"
                  autoComplete="username"
                  placeholder="Your email"
                  value={email}
                  style={{ marginTop: "0.5rem" }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <label>
                password
                <input
                  id="password"
                  type="password"
                  placeholder="Your password"
                  autoComplete="current-password"
                  value={password}
                  style={{ marginTop: "0.5rem" }}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              {errorMsg && (
                <div style={{ marginTop: "1rem", color: "var(--sb-red)" }}>
                  Error: {errorMsg}
                </div>
              )}
              {loading && <div style={{ marginTop: "1rem" }}>Loading...</div>}
              <button
                className="button block"
                aria-live="polite"
                style={{ marginTop: "1rem" }}
              >
                Sign In
              </button>
            </form>
            <button
              style={{
                marginTop: "1rem",
                background: "transparent",
                color: "var(--sb-medium-dark-gray)",
              }}
              onClick={() => setIsSigningUp(true)}
            >
              {"Sign Up"}
            </button>
            <div style={{ marginTop: "1rem" }}>
              <SignInWithGoogleButton
                googleClientId="294183009933-b1ttmaqtblf6srf9ub787565u06su610.apps.googleusercontent.com"
                onClick={handleSignInWithGoogle}
              />
            </div>
          </>
        )}
        {isSigningUp && (
          <>
            <form onSubmit={handleSignUp}>
              <input
                id="email"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                id="password"
                type="password"
                placeholder="Your password"
                value={password}
                style={{ marginTop: "0.5rem" }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                id="name"
                type="text"
                placeholder="Your name"
                value={name}
                style={{ marginTop: "0.5rem" }}
                onChange={(e) => setName(e.target.value)}
              />
              {errorMsg && (
                <div style={{ marginTop: "1rem", color: "var(--sb-red)" }}>
                  Error: {errorMsg}
                </div>
              )}
              {loading && <div style={{ marginTop: "1rem" }}>Loading...</div>}
              <button
                className="button block"
                aria-live="polite"
                style={{ marginTop: "1rem" }}
              >
                Sign Up
              </button>
            </form>
            <button
              style={{
                marginTop: "1rem",
                background: "transparent",
                color: "var(--sb-medium-dark-gray)",
              }}
              onClick={() => setIsSigningUp(false)}
            >
              {"Sign In"}
            </button>
            <div style={{ marginTop: "1rem" }}>
              <SignInWithGoogleButton
                googleClientId="294183009933-b1ttmaqtblf6srf9ub787565u06su610.apps.googleusercontent.com"
                onClick={handleSignInWithGoogle}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
