import React, { useEffect, useState } from "react";
import mapkit, { lookup } from "../clients/mapkitClient";
import { debounce } from "../helpers/reactHelpers";

interface AddressInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  id: string;
  onCoordinateChange?: (coordinate: mapkit.Coordinate) => void;
}

export function AddressInput(props: AddressInputProps) {
  const mapkitSearchRef = React.useRef(
    new mapkit.Search({
      // limitToCountries: "US",
      // includeAddresses: false,
      includePointsOfInterest: true,
      pointOfInterestFilter: mapkit.PointOfInterestFilter.including([
        mapkit.PointOfInterestCategory.Marina,
      ]),
    })
  );
  const [inputValue, setValue] = useState("");
  const [inputOptions, setInputOptions] = useState<
    Array<{ label: string; coordinate: mapkit.Coordinate }>
  >([]);

  const handleChangeCoordinates = async (address: string) => {
    if (props.onCoordinateChange) {
      try {
        const result = await lookup(address);
        if (!result) return;
        props.onCoordinateChange(result);
      } catch (e) {
        console.error("Could not lookup coordinates", e);
      }
    }
  };

  const handleChangeCoordinatesDebounced = debounce(
    handleChangeCoordinates,
    500
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.currentTarget.value);
    props.onChange?.(e);
    handleChangeCoordinatesDebounced(e.currentTarget.value);
  };

  // Search and populate dropdown data while the user types.
  useEffect(() => {
    if (!mapkitSearchRef.current || !inputValue) {
      setInputOptions([]);
      return;
    }

    mapkitSearchRef.current.autocomplete(inputValue, (error, data) => {
      if (error) {
        console.error(`[address-input] Autocomplete error: `, error);
        setInputOptions([]);
        return;
      }

      setInputOptions(
        data.results.map((res) => ({
          label: res.displayLines.join(", "),
          coordinate: res.coordinate,
        }))
      );
    });
  }, [inputValue]);

  return (
    <>
      <input
        {...props}
        type="text"
        className="mapSearchInput"
        onChange={handleChange}
        list={`${props.id}-options`}
      />
      <datalist id={`${props.id}-options`}>
        {inputOptions.map(
          ({ label, coordinate }) =>
            coordinate && <option key={label}>{label}</option>
        )}
      </datalist>
      <div id="results" className="mapSearchResults"></div>
    </>
  );
}
