import React, { FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppPageTitle } from "../shared/components/AppPageTitle";
import {
  createStripeAccountLink,
  insertCrew,
  useUserCrew,
} from "../shared/clients/crewClient";
import { CheckIcon } from "../shared/components/Icons";
import { Avatar } from "../shared/components/Avatar";
import { RouteName } from "../routes";
import { AddressInput } from "../shared/components/AddressInput";
import { useEnlistFormState } from "../shared/clients/enlistForm";
import { useUserSessionData } from "../shared/clients/userClient";

const AVATAR_SIZE = 150;
interface EnlistProps {}

export function CrewSignupLinkCard() {
  return (
    <div
      style={{
        border: "solid 1px var(--sb-medium-gray)",
        padding: "1rem",
      }}
    >
      <div>
        {
          "You are not yet a crew member. To start accepting voyages, please enlist."
        }
      </div>
      <Link to="/enlist">
        <button
          className="button block"
          style={{
            background: "var(--sb-blue-0)",
            width: "auto",
            marginTop: "0.5rem",
            justifySelf: "end",
          }}
        >
          Enlist
        </button>
      </Link>
    </div>
  );
}

export function FallbackToSignup(props: React.PropsWithChildren<{}>) {
  const crew = useUserCrew();
  if (crew.status === "loading") {
    return null;
  }
  const crewId = crew?.data?.id;
  if (!crewId) return <CrewSignupLinkCard />;
  return <>{props.children}</>;
}

/**
 * The workflow through which a crew member is signed up to start
 * accepting voyage requests.
 */
export function Enlist(props: EnlistProps) {
  const navigate = useNavigate();
  const crewQuery = useUserCrew();
  const crew = crewQuery.data;
  const userSession = useUserSessionData();

  const { crewForm, setCrewForm } = useEnlistFormState(
    ({ crewForm, setCrewForm }) => ({
      crew,
      crewForm,
      setCrewForm,
    })
  );
  const [isComplete, setIsComplete] = React.useState(false);
  const [nextStepUrl, setNextStepUrl] = React.useState("");
  const isInProgress = React.useRef(false);

  // Only submits the crew data, as the avatar is submitted individually.
  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { home_latitude, home_longitude, daily_rate_dollars } =
      crewForm ?? {};

    const isValidForm =
      home_latitude != null &&
      home_longitude != null &&
      daily_rate_dollars != null &&
      userSession?.user?.id != null;

    if (!isValidForm) {
      // TODO: Show errors to user.
      console.warn("[enlist] Not submitting invalid form");
      return;
    }

    console.log(
      `[enlist] Finishing enlist with ${JSON.stringify({
        ...crewForm,
      })}`
    );
    const { error } = await insertCrew({
      user_id: userSession.user.id,
      home_latitude,
      home_longitude,
      daily_rate_dollars,
    });

    if (error) {
      console.error("[enlist] Creating crew failed: ", e);
      return;
    }

    console.log(`[enslit] Enlist complete.`);
    navigate("/crew");
  }

  React.useEffect(() => {
    async function doCreateStripeAccountLink() {
      // Create a new link no matter what, use the returned account
      // to determine if we need to use it.
      const { data } = await createStripeAccountLink();

      if (!data?.account?.id) {
        console.error(`[enlist] No account.`);
        return;
      }

      if (data?.account.details_submitted) {
        console.log("[enlist] Found completed account.");
        setIsComplete(true);
        return;
      }

      console.log("[enlist] Found incomplete account.");
      setNextStepUrl(data?.accountLink.url);
    }

    if (!isInProgress.current) {
      doCreateStripeAccountLink();
    }
  }, []);

  // I suppose this could happen...
  if (crew) {
    return (
      <div
        className="content"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ flex: "2", padding: "4rem" }}>
          <CheckIcon />
        </div>
        <div style={{ flex: "1", textAlign: "center" }}>
          <h3>{"You are a crew member!"}</h3>
          <span>
            Visit your <Link to={RouteName.crewVoyages}>Crew Dashboard</Link> to
            start receiving offers.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="content">
      <AppPageTitle>Crew Profile</AppPageTitle>
      <form onSubmit={onSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Avatar size={AVATAR_SIZE} />
          </div>
        </div>
        <label htmlFor="home_address">Home Address</label>
        <AddressInput
          id="home_address-input"
          name="home_address"
          defaultValue={crewForm?.homeAddressDisplay}
          onChange={(e) =>
            setCrewForm({ homeAddressDisplay: e.currentTarget.value })
          }
          onCoordinateChange={(coordinates) =>
            setCrewForm({
              home_latitude: coordinates.latitude,
              home_longitude: coordinates.longitude,
            })
          }
        ></AddressInput>
        <label htmlFor="daily_rate_dollars">Daily Rate (dollars)</label>
        <input
          id="daily_rate_dollars"
          type="number"
          step="1"
          value={crewForm?.daily_rate_dollars || 0}
          onChange={(e) =>
            setCrewForm({ daily_rate_dollars: parseInt(e.target.value) })
          }
        />
        {isComplete && (
          <input
            style={{
              marginTop: "1rem",
              fontWeight: "bold",
              background: "var(--sb-yellow)",
              border: 0,
              cursor: "pointer",
            }}
            type="submit"
            value="Finish"
          ></input>
        )}
        {!isComplete && (
          <div style={{ marginTop: "1rem" }}>
            <a href={nextStepUrl}>Sign Up as Crew</a>
          </div>
        )}
      </form>
    </div>
  );
}
