import React from "react";
import {
  cancelVoyage,
  completeVoyage,
  getStatus,
  startVoyage,
  useOwnerVoyagesNotStarted,
  useVoyage,
} from "../shared/clients/voyagesClient";
import { ButtonRow } from "../shared/components/ButtonRow";
import { useUserCrew } from "../shared/clients/crewClient";
import { VoyageMap } from "../shared/components/VoyageMap";
import { Card } from "../shared/components/Card";
import { Address } from "../shared/components/AddressDisplay";
import { PageContent } from "../shared/components/Page";
import { useParams } from "react-router";

export const DeliveryPage: React.FC<{}> = (props) => {
  const crew = useUserCrew();
  const { deliveryId } = useParams();
  const delivery = useVoyage(deliveryId);

  if (delivery.status === "loading" || crew.status === "loading") {
    return <div>Loading...</div>;
  }

  if (!crew.data || !delivery.data) return null;

  return <Delivery voyage={delivery.data} crew={crew.data} />;
};

/**
 * Control interface for a captain on a trip.
 * There can either be:
 * - A trip in progress to control from here
 * - A handful of trips that have not been started that can be chosen
 * - No trips at all and nothing to do
 */
const Delivery: React.FC<{
  voyage: starboard.Voyage;
  crew: starboard.tables["crew"];
}> = (props) => {
  return (
    <PageContent
      style={{
        display: "grid",
        gridTemplateRows: "1fr 4rem",
        gridRowGap: "0.5rem",
      }}
    >
      <Card
        style={{ marginBottom: 0 }}
        dataTestId={`delivery-card-${props.voyage.id}`}
      >
        <VoyageMap style={{ height: "100%" }} voyage={props.voyage} />
      </Card>
      {props.voyage && (
        <DeliveryController crew={props.crew} voyage={props.voyage} />
      )}
    </PageContent>
  );
};

const DeliverySelector: React.FC<{ crew: starboard.tables["crew"] }> = (
  props
) => {
  const unstartedVoyages = useOwnerVoyagesNotStarted();
  const [selectedVoyage, selectVoyage] = React.useState<starboard.Voyage>();
  if (!selectedVoyage && unstartedVoyages.data?.[0]) {
    selectVoyage(unstartedVoyages.data[0].offer.voyage_crew_position.voyage);
  }

  if (unstartedVoyages.status === "loading") {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {!selectedVoyage && <div>Accept a delivery to control it here.</div>}
      {selectedVoyage && (
        <>
          <Card>
            <label>Route</label>
            <div>
              <Address value={selectedVoyage.from_address} />
              <span
                style={{
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  fontWeight: "bold",
                  color: "var(--sb-medium-dark-gray)",
                }}
              >
                {"->"}
              </span>
              <Address value={selectedVoyage.to_address} />
            </div>
          </Card>
          <VoyageMap style={{ flex: 1 }} voyage={selectedVoyage} />
          <div style={{ marginTop: "0.5rem" }}>
            <DeliveryControlRow voyage={selectedVoyage} crew={props.crew} />
          </div>
        </>
      )}
    </div>
  );
};

const DeliveryController: React.FC<{
  voyage: starboard.Voyage;
  crew: starboard.tables["crew"];
  onCommandCompleted?: () => unknown;
}> = (props) => {
  return (
    <div
      style={{
        gridRow: "2",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <DeliveryControlRow {...props} />
    </div>
  );
};

const DeliveryControlRow: React.FC<{
  voyage: starboard.Voyage;
  crew: starboard.tables["crew"];
  onCommandCompleted?: () => unknown;
}> = (props) => {
  const { voyage, crew, onCommandCompleted } = props;
  switch (getStatus(voyage)) {
    case "cancelled":
      return <span>Cancelled</span>;

    case "completed":
      return <span>Completed</span>;

    case "in_progress":
      return (
        <>
          <button
            style={{
              backgroundColor: "var(--sb-dark-gray)",
              fontWeight: "bold",
              color: "var(--sb-lightest-gray)",
              padding: "1rem",
              width: "45%",
            }}
            onClick={() => cancelVoyage(props.voyage.id, props.crew.id)}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "var(--sb-dark-gray)",
              fontWeight: "bold",
              color: "var(--sb-lightest-gray)",
              padding: "1rem",
              width: "45%",
            }}
            onClick={() => completeVoyage(props.voyage.id, props.crew.id)}
          >
            Complete
          </button>
        </>
      );

    case "not_started":
      return (
        <>
          <button
            style={{
              backgroundColor: "var(--sb-dark-gray)",
              fontWeight: "bold",
              color: "var(--sb-lightest-gray)",
              padding: "1rem",
              width: "45%",
            }}
            onClick={async () => {
              await cancelVoyage(voyage.id, crew.id);
              onCommandCompleted?.();
            }}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "var(--sb-dark-gray)",
              fontWeight: "bold",
              color: "var(--sb-lightest-gray)",
              padding: "1rem",
              width: "45%",
            }}
            onClick={() => startVoyage(voyage.id, crew.id)}
          >
            Start
          </button>
        </>
      );

    default:
      return <div>Unknown Status</div>;
  }
};
