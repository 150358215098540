import React from "react";
import { OfferStatusValue } from "../shared/clients/offersClient";

const OfferStatusLabelInner: React.FC<{ status: OfferStatusValue }> = (
  props
) => {
  switch (props.status) {
    case "open":
      return <span>Open</span>;

    case "accepted":
      return <span>Accepted</span>;

    case "rejected":
      return <span>Rejected</span>;

    default:
      return <span>Unknown Status</span>;
  }
};
export const OfferStatusLabel = React.memo(OfferStatusLabelInner);
