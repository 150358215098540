import { useEffect, useState, useRef } from "react";
// import "google.d.ts";

export interface UseLoadGsiScriptOptions {
  /**
   * Nonce applied to GSI script tag. Propagates to GSI's inline style tag
   */
  nonce?: string;
  /**
   * Callback fires on load [gsi](https://accounts.google.com/gsi/client) script success
   */
  onScriptLoadSuccess?: () => void;
  /**
   * Callback fires on load [gsi](https://accounts.google.com/gsi/client) script failure
   */
  onScriptLoadError?: () => void;
}

function useLoadGsiScript(options: UseLoadGsiScriptOptions = {}): boolean {
  const { nonce, onScriptLoadSuccess, onScriptLoadError } = options;

  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] =
    useState(false);

  const onScriptLoadSuccessRef = useRef(onScriptLoadSuccess);
  onScriptLoadSuccessRef.current = onScriptLoadSuccess;

  const onScriptLoadErrorRef = useRef(onScriptLoadError);
  onScriptLoadErrorRef.current = onScriptLoadError;

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://accounts.google.com/gsi/client";
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.nonce = nonce;
    scriptTag.onload = () => {
      setScriptLoadedSuccessfully(true);
      onScriptLoadSuccessRef.current?.();
    };
    scriptTag.onerror = () => {
      setScriptLoadedSuccessfully(false);
      onScriptLoadErrorRef.current?.();
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [nonce]);

  return scriptLoadedSuccessfully;
}

export function SignInWithGoogleButton(props: {
  googleClientId: string;
  onClick: () => void;
}) {
  const scriptLoadedSuccessfully = useLoadGsiScript();

  useEffect(() => {
    if (!scriptLoadedSuccessfully || !window.google) {
      return;
    }

    window.google.accounts.id.initialize({
      client_id: props.googleClientId,
      callback: (response) => {
        if (!response.credential) {
          console.error("No credential");
          return;
        }
      },
    });

    const button = document.getElementById("buttonDiv");
    if (!button) {
      console.error("No button to mount sign in with google to");
      return;
    }

    window.google.accounts.id.renderButton(
      button,
      {
        theme: "outline",
        size: "large",
        click_listener: () => {
          console.log("hello");
        },
      } // customization attributes
    );

    // window.google.accounts.id.prompt(); // also display the One Tap dialog
  }, [props.googleClientId, scriptLoadedSuccessfully]);

  return (
    <div onClick={props.onClick}>
      {/* The google button is disabled with pointerEvents: none so that we can override the click effect */}
      <div id="buttonDiv" style={{ pointerEvents: "none" }}></div>
    </div>
  );
}
