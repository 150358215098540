import React from "react";
import { downloadImage } from "../clients/crewClient";
import { supabase } from "../clients/supabaseClient";
import { useUserProfile } from "../clients/userClient";

export const VesselAvatar: React.FC<{ vesselId: number; size: number }> = ({
  vesselId,
  size,
}) => {
  const [vesselImgUrl, setVesselImgUrl] = React.useState<string>();
  React.useEffect(() => {
    let ignore = false;

    async function initializeAvatarUrl() {
      if (!vesselId) {
        console.warn("No vesselId id, not fetching avatar_url");
        return;
      }
      const { data } = await supabase
        .from("vessel")
        .select("image_url")
        .eq("id", vesselId)
        .single();

      const url = data?.image_url;
      const imgUrl = url ? await downloadImage(url) : undefined;
      if (ignore) {
        return;
      }
      setVesselImgUrl(imgUrl);
    }

    initializeAvatarUrl();
    return () => {
      ignore = true;
    };
  }, [vesselId]);

  return vesselImgUrl ? (
    <img
      src={vesselImgUrl}
      alt="Avatar"
      className="avatar image"
      style={{ height: size, width: size }}
    />
  ) : (
    <div
      style={{
        height: "64px",
        width: "64px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "var(--sb-light-gray)",
        borderRadius: "0.5rem",
      }}
    ></div>
  );
};

export const ProfileAvatar: React.FC<{ profileId: string; size: number }> = ({
  profileId,
  size,
}) => {
  const [avatarImgUrl, setAvatarImgUrl] = React.useState<string>();

  // Initialize the profile avatar as it may already be populated.
  React.useEffect(() => {
    let ignore = false;

    async function initializeAvatarUrl() {
      if (!profileId) {
        console.warn("No profile id, not fetching avatar_url");
        return;
      }
      const { data } = await supabase
        .from("profile")
        .select("avatar_url")
        .eq("id", profileId)
        .single();

      const url = data?.avatar_url;
      const imgUrl = url ? await downloadImage(url) : undefined;
      if (ignore) {
        return;
      }
      setAvatarImgUrl(imgUrl);
    }

    initializeAvatarUrl();
    return () => {
      ignore = true;
    };
  }, [setAvatarImgUrl, profileId]);

  return avatarImgUrl ? (
    <img
      src={avatarImgUrl}
      alt="Avatar"
      className="avatar image"
      style={{ height: size, width: size }}
    />
  ) : (
    <div
      className="avatar no-image"
      style={{
        height: size,
        width: size,
        background: "var(--sb-medium-gray)",
        borderRadius: "0.5rem",
      }}
    />
  );
};

export const Avatar: React.FC<{ readonly?: boolean; size: number | string }> = (
  props
) => {
  const profileQuery = useUserProfile();
  const profileId = profileQuery.data?.id;
  const [uploading, setUploading] = React.useState(false);
  const [avatarImgUrl, setAvatarImgUrl] = React.useState<string>();

  const onAvatarInputChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      async (event) => {
        try {
          setUploading(true);

          if (!event.target.files || event.target.files.length === 0) {
            throw new Error("You must select an image to upload.");
          }

          const file = event.target.files[0];
          const fileExt = file.name.split(".").pop();
          const uniquePrefix = Date.now(); // TODO : Replace with user id?
          const fileName = `${uniquePrefix}.${fileExt}`;
          const filePath = `${fileName}`;

          let { error: uploadError } = await supabase.storage
            .from("avatars")
            .upload(filePath, file, { upsert: true });

          if (uploadError) {
            throw uploadError;
          }

          if (!profileId) {
            throw new Error("Can't find your profile");
          }

          // Immediately set in db, don't wait for any kind of 'submit'.
          await supabase
            .from("profile")
            .update({ avatar_url: filePath })
            .eq("id", profileId);

          const imgUrl = await downloadImage(filePath);
          setAvatarImgUrl(imgUrl);
        } catch (error) {
          alert("Error uploading avatar!");
          console.log(error);
        } finally {
          setUploading(false);
        }
      },
      [setAvatarImgUrl, profileId]
    );

  // Initialize the profile avatar as it may already be populated.
  React.useEffect(() => {
    let ignore = false;

    async function initializeAvatarUrl() {
      if (!profileId) {
        console.warn("No profile id, not fetching avatar_url");
        return;
      }
      const { data } = await supabase
        .from("profile")
        .select("avatar_url")
        .eq("id", profileId)
        .single();

      const url = data?.avatar_url;
      const imgUrl = url ? await downloadImage(url) : undefined;
      if (ignore) {
        return;
      }
      setAvatarImgUrl(imgUrl);
    }

    initializeAvatarUrl();
    return () => {
      ignore = true;
    };
  }, [setAvatarImgUrl, profileId]);

  return (
    <>
      {avatarImgUrl ? (
        <img
          src={avatarImgUrl}
          alt="Avatar"
          className="avatar image"
          style={{ height: props.size, width: props.size }}
        />
      ) : (
        <div
          className="avatar no-image"
          style={{
            height: props.size,
            width: props.size,
            background: "var(--sb-medium-gray)",
            borderRadius: "0.5rem",
          }}
        />
      )}
      <div style={{ width: props.size }}>
        <label
          htmlFor="single"
          className="button block"
          style={{
            marginTop: "0.125rem",
            background: "transparent",
            color: "var(--sb-color-quiet)",
            padding: "0.25rem",
            textTransform: "none",
          }}
        >
          Upload New
        </label>
        <input
          style={{
            visibility: "hidden",
            position: "absolute",
          }}
          type="file"
          id="single"
          accept="image/*"
          onChange={onAvatarInputChange}
          disabled={uploading}
        />
      </div>
    </>
  );
};
