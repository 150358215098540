import { useEffect, useMemo, useRef, useState } from "react";
import { getRegion, lookup, padRegion } from "../clients/mapkitClient";
import { addressToString } from "./AddressDisplay";

export function isCoordinateEqual(
  a: mapkit.Coordinate | undefined,
  b: mapkit.Coordinate | undefined
): boolean {
  if (!a && !b) return true;
  if (a && b && a.equals(b)) return true;
  return false;
}

function getUserCurrentPosition(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 15, // ms in cache
    });
  });
}

async function getUserCurrentCoordinate() {
  const pos = await getUserCurrentPosition();
  return new mapkit.Coordinate(pos.coords.latitude, pos.coords.longitude);
}

export function addAnnotationIfNone(
  map: mapkit.Map,
  coord: mapkit.Coordinate,
  opts: mapkit.MarkerAnnotationConstructorOptions
) {
  if (map.annotations.some((a) => a.coordinate.equals(coord))) {
    return;
  }

  const annotation = new mapkit.MarkerAnnotation(coord, opts);

  map.addAnnotation(annotation);

  // The annotation instance can be used in calls to remove.
  return annotation;
}

function addRouteOverlay(
  map: mapkit.Map,
  from: mapkit.Coordinate,
  to: mapkit.Coordinate
) {
  map.setRegionAnimated(padRegion(getRegion(...[from, to])), true);

  const overlay = new mapkit.PolylineOverlay([from, to], {
    style: new mapkit.Style({
      lineWidth: 2,
      lineJoin: "round",
      lineDash: [8, 4],
      strokeColor: "#0657ff",
    }),
  });

  map.addOverlay(overlay);

  return overlay;
}

export function addRouteAnnotation(
  map: mapkit.Map,
  from: mapkit.Coordinate | undefined,
  to: mapkit.Coordinate | undefined
): [
  mapkit.MarkerAnnotation | undefined,
  mapkit.MarkerAnnotation | undefined,
  mapkit.PolylineOverlay | undefined
] {
  return [
    from &&
      addAnnotationIfNone(map, from, {
        color: "#f9a800",
        title: "From",
        animates: false,
      }),
    to &&
      addAnnotationIfNone(map, to, {
        color: "#f9a800",
        title: "To",
        animates: false,
      }),
    from && to && addRouteOverlay(map, from, to),
  ];
}

export function useAddressCoordinate(address?: string) {
  const [coord, setCoord] = useState<mapkit.Coordinate>();

  useEffect(() => {
    let isMounted = true;

    async function lookupCoordinate() {
      if (!address) {
        setCoord(undefined);
        return;
      }

      const result = await lookup(address);
      if (!isMounted) return;
      setCoord(result);
    }

    lookupCoordinate();

    return () => {
      isMounted = false;
    };
  }, [address]);

  return coord;
}

export function useVoyageCoordinates(voyage: starboard.Voyage) {
  return [
    useAddressCoordinate(addressToString(voyage.from_address)),
    useAddressCoordinate(addressToString(voyage.to_address)),
  ];
}

export function useUserCoordinate() {
  const [user, setUser] = useState<mapkit.Coordinate>();
  useEffect(() => {
    let isMounted = true;

    async function lookupCoordinates() {
      const userRes = await getUserCurrentCoordinate();

      if (isMounted) {
        setUser(userRes);
      }
    }

    lookupCoordinates();
    return () => {
      isMounted = false;
    };
  }, []);

  return user;
}

export function useMap(parentElementId: string) {
  const [isRendered, setIsRendered] = useState(false);
  useEffect(() => setIsRendered(true), []);

  const mapRef = useRef<mapkit.Map>();
  const map = useMemo(() => {
    if (!isRendered) {
      return;
    }

    if (!parentElementId || !document.getElementById(parentElementId)) {
      return;
    }

    if (mapRef.current) {
      mapRef.current.destroy();
    }

    const mapInstance = new mapkit.Map(parentElementId, {
      region: new mapkit.CoordinateRegion(
        new mapkit.Coordinate(42, -71.0589),
        new mapkit.CoordinateSpan(35, 15)
      ),
    });
    mapRef.current = mapInstance;

    return mapInstance;
  }, [parentElementId, isRendered]);

  return map;
}
