import { PostgrestResponse } from "@supabase/postgrest-js";
import { CrewSearchParams } from "../../owner/CrewSearch";
import { lookup } from "./mapkitClient";
import { supabase } from "./supabaseClient";
import { executeCommand, insert } from "./supabaseHelpers";
import {
  fallbackOnNullQueryArg,
  useQuery,
  useSubQuery,
} from "../helpers/reactHelpers";
import { useUserIdQuery } from "./userClient";

export const selectCrewForDeliveryFromSearch = async (
  crewSearchParams: CrewSearchParams
) => {
  const targetCoordinates = await lookup(crewSearchParams.from);
  if (!targetCoordinates) return;
  return selectCrewForDelivery([
    targetCoordinates.latitude,
    targetCoordinates.longitude,
  ]);
};

// Pass through to stored procedure.
export const selectCrewForDelivery = (to: [number, number]) =>
  supabase.rpc("select_crew_for_delivery", {
    target_latitude: to[0],
    target_longitude: to[1],
  }) as unknown as Promise<PostgrestResponse<starboard.tables["crew"]>>;

// Still logging 406 error - see https://github.com/supabase/postgrest-js/issues/361
export const selectCrewForUser = fallbackOnNullQueryArg((userId: string) =>
  supabase.from("crew").select().eq("user_id", userId).maybeSingle()
);

const selectCrewId = (crewQuery: Awaited<ReturnType<typeof useUserCrew>>) =>
  crewQuery.data?.id;

export const useUserCrew = () => {
  const userIdQuery = useUserIdQuery();
  return useQuery(selectCrewForUser, [userIdQuery]);
};

export const useUserCrewId = () => {
  const userCrewQuery = useUserCrew();
  return useSubQuery(userCrewQuery, selectCrewId);
};

// TODO (mac): should be nested in crew to begin with?
export const selectStripeAccountWrapper = (userId: string) =>
  supabase.from("stripe_account").select().eq("id", userId).single();

export const insertCrew = (
  seed: starboard.db["public"]["Tables"]["crew"]["Insert"]
) =>
  insert("crew", {
    ...seed,
    is_available: true,
  });

// FIXME: Share with function. Design by contract.
interface CreateStripeCustomerResponse {
  accountLink: any;
  account: any;
}
export const createStripeAccountLink = () =>
  executeCommand<CreateStripeCustomerResponse>("enlist");

export async function downloadImage(path: string) {
  try {
    const { data, error } = await supabase.storage
      .from("avatars")
      .download(path);
    if (error) {
      throw error;
    }

    if (!data) {
      throw new Error("No data");
    }

    return URL.createObjectURL(data);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
}
